import {TenantDetailModel} from "../models/tenant-detail.model";
import {asyncScheduler, Observable, scheduled} from "rxjs";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {
  LipoFormCheckbox,
  LipoFormControlModel,
  LipoFormNumeric,
  LipoFormPassword,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {SystemSaveDetailModel} from "../../../system/components/models/system-detail.model";

export function getTenantDetailFormFields(model: TenantDetailModel, isAdmin: boolean, onResetIconClicked: (controlModel: LipoFormControlModel) => Promise<any>): Observable<LipoFormGroupModel[]> {

  const checkBoxes: LipoFormControlModel[] = [
    new LipoFormCheckbox({
      value: new FormControl(model.active),
      key: 'active',
      label: 'active',
    }),
    new LipoFormCheckbox({
      value: new FormControl({ value: model.checkInbound, disabled: true }),
      key: 'checkInbound',
      label: 'check_inbound',
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.testSystem),
      key: 'testSystem',
      label: 'testSystem',
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.suspended),
      key: 'suspended',
      label: 'suspended',
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.allowWithoutAuth),
      key: 'allowWithoutAuth',
      label: 'allow_without_auth',
    }),
  ]

  const technicalFields: LipoFormControlModel[] = [
    new LipoFormNumeric({
      value: new FormControl(model.port),
      key: 'port',
      label: 'port',
      tooltip: 'tooltip.port',
    }),
    new LipoFormNumeric({
      value: new FormControl(model.externalPort),
      key: 'externalPort',
      label: 'external-port',
      tooltip: 'tooltip.external-port',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.serviceName),
      key: 'serviceName',
      label: 'serviceName',
      tooltip: 'tooltip.serviceName'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.duifLicense),
      key: 'duifLicense',
      label: 'duifLicense',
      tooltip: 'tooltip.duifLicense'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.installNo),
      key: 'installNo',
      label: 'installNo',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.endpointUrl),
      key: 'endpointUrl',
      label: 'endpointUrl',
    }),
    new LipoFormTextbox({
      value: new FormControl({value: model.proxyName, disabled: true}),
      key: 'proxyName',
      label: 'proxyName.name',
    }),
  ]

  const gitlabFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.gitlabJobName),
      key: 'gitlabJobName',
      label: 'gitlab_job_name',
    }),
    new LipoFormNumeric({
      value: new FormControl(model.gitlabProjectId),
      key: 'gitlabProjectId',
      label: 'gitlab_project_id',
      tooltip: 'tooltip.gitlabProjectId'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.gitlabRefName),
      key: 'gitlabRefName',
      label: 'gitlab_ref_name'
    }),
  ];

  const artifactFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.artifactDate),
      key: 'artifactDate',
      label: 'artifactDate',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.directory),
      key: 'directory',
      label: 'directory',
      tooltip: 'tooltip.directory',
    }),
  ]

  const sapFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.sapCompanyDB, Validators.required),
      key: 'sapCompanyDB',
      label: 'sapCompanyDB',
      tooltip: 'tooltip.sapCompanyDB'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.sapUser),
      key: 'sapUser',
      label: 'sapUser',
    }),
    new LipoFormPassword({
      value: new FormControl(model.sapPassword),
      key: 'sapPassword',
      label: 'sapPassword',
      settings: {
        onResetIconClick: onResetIconClicked,
        showResetIcon: !!model.sapPassword,
        readonly: model.sapPassword?.startsWith('encrypt_') || false
      }
    }),
  ];

  let formGroupModels: LipoFormGroupModel[] = [
    new LipoFormGroupModel({key: 'sap', label: 'sap', order: 1, controls: sapFields}),
    new LipoFormGroupModel({key: 'checkBoxes', order: 5, controls: checkBoxes})
  ];

  if (isAdmin) {
    formGroupModels.push(new LipoFormGroupModel({
      key: 'artifact',
      label: 'artifact',
      order: 2,
      controls: artifactFields
    }));
    formGroupModels.push(new LipoFormGroupModel({key: 'gitlab', label: 'gitlab', order: 3, controls: gitlabFields}));
    formGroupModels.push(new LipoFormGroupModel({
      key: 'technical',
      label: 'technical',
      order: 4,
      controls: technicalFields
    }));
  }

  return scheduled([formGroupModels], asyncScheduler);
}

export function getAdminTenantDetailSaveFormFields(model: SystemSaveDetailModel): Observable<LipoFormControlModel[]> {
  const adminFields: LipoFormControlModel[] = [
    new LipoFormNumeric({
      value: new FormControl(model.gitlabProjectId),
      key: 'gitlabProjectId',
      label: 'gitlab_project_id',
      tooltip: 'tooltip.gitlabProjectId',
    }),
  ];
  return scheduled([adminFields], asyncScheduler);
}
