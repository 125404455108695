import moment from 'moment';
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoCardModel} from "../../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LicenseOverviewCardBodyComponent} from "../license-overview-card-body/license-overview-card-body.component";
import {ProductAppLicenseServiceModel} from "../../../product/services/models/product-service.model";
import {CustomerAppLicenseServiceModel} from "../../../customer/services/models/customer-service.model";
import {SystemAppLicenseServiceModel} from "../../../system/services/models/system-service.model";
import {TenantAppLicenseServiceModel} from "../../../tenant/services/models/tenant-service.model";

export class AppLicenseOverviewModel implements LipoModelInterface {
  constructor(
    public id: number | null = null,
    public purchaseDate: moment.Moment,
    public expiryDate: moment.Moment,
    public logo: string | null = null,
    public tenant: TenantAppLicenseServiceModel,
    public system: SystemAppLicenseServiceModel,
    public customer: CustomerAppLicenseServiceModel,
    public product: ProductAppLicenseServiceModel,
    public isActive: boolean = false,
    public isActiveText: 'active' | 'idle' = "idle",
  ) {
  }

  toLipoCardModel(): LipoCardModel {
    return new LipoCardModel({
      id: this.id,
      route: {commands: [LipoRouteEnum.SYSTEM, this.system.id, LipoRouteEnum.TENANT, this.tenant.id]},
      title: this.product.name,
      subtitle: this.customer.name,
      body: {component: LicenseOverviewCardBodyComponent, inputs: {licenseOverviewModel: this}},
      logoUrl: this.logo,
    });
  }

  getId(): number | null {
    return this.id;
  }
}
