import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {ProductPriceServiceModel} from "./models/product-price-service.model";
import {ProductServiceModel} from "../../product/services/models/product-service.model";

@Injectable({
  providedIn: 'root'
})
export class ProductPriceDataService {

  private readonly endpoint: string;
  private readonly api: string;
  private readonly priceSuffix: string = "price";

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {
    this.endpoint = this.config.configuration.api.endpoints.product;
    this.api = this.config.configuration.api.backendUrl;
  }

  createProductPrice(productPrice: ProductPriceServiceModel, productId: number): Observable<ProductServiceModel> {
    return this.http.post<ProductServiceModel>(`${this.api}/${this.endpoint}/${productId}/${this.priceSuffix}`, productPrice);
  }

  updateProductPrice(productId: number, productPrice: ProductPriceServiceModel): Observable<ProductPriceServiceModel> {
    return this.http.put<ProductServiceModel>(`${this.api}/${this.endpoint}/${productId}/${this.priceSuffix}`, productPrice).pipe(
      map((productServiceModel: ProductServiceModel) => this.getProductPrice(productServiceModel, productPrice.id))
    );
  }

  deleteProductPrice(productId: number, productPriceId: number): Observable<boolean> {
    return this.http.delete<ProductServiceModel>(`${this.api}/${this.endpoint}/${productId}/${this.priceSuffix}/${productPriceId}`).pipe(
      map((productServiceModel: ProductServiceModel) => {return this.isProductPriceRemoved(productServiceModel, productPriceId);})
    );
  }

  private getProductPrice(productServiceModel: ProductServiceModel, productPriceId: number | null): ProductPriceServiceModel {
    const updateProductPrice = productServiceModel.productPrice.find(price => price.id === productPriceId);
    return updateProductPrice || {} as ProductPriceServiceModel;
  }

  private isProductPriceRemoved(productServiceModel: ProductServiceModel, productPriceId: number): boolean {
    return !productServiceModel.productPrice.some(price => price.id === productPriceId);
  }
}
