export class LicenseWizardModel {
  public systemId: number | null;
  public tenantId: number | null;
  public productId: number | null;
  public productPriceId: number | null;

  constructor(data: {
    systemId?: number | null;
    tenantId?: number | null;
    productId?: number | null;
    productPriceId?: number | null;
  } = {}) {
    this.systemId = data.systemId ?? null;
    this.tenantId = data.tenantId ?? null;
    this.productId = data.productId ?? null;
    this.productPriceId = data.productPriceId ?? null;
  }
}
