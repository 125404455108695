export function toStrDict(obj: any): { [key: string]: string } {
  const result: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      result[String(key)] = JSON.stringify(value);
    } else if (typeof value === 'object' && value !== null) {
      result[String(key)] = JSON.stringify(toStrDict(value));
    } else {
      result[String(key)] = String(value);
    }
  }

  return result;
}

export function fromStrDict<T>(obj: { [key: string]: string }): T {
  const result: any = {};

  for (const [key, value] of Object.entries(obj)) {
    try {
      result[key] = JSON.parse(value);
    } catch (e) {
      result[key] = value;
    }
  }

  return result;
}
