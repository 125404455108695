<mat-card class="card" [ngClass]="{'is-focusable': !disabled}" role="button" (click)="!disabled && navigateToRoute()"
          mat-ripple [matRippleDisabled]="disabled">
  <mat-card-header class="card-header">
    <div class="card-header-img-container">
      <img class="card-header-img"
           [ngSrc]="content.logoUrl ?? defaultLogo"
           alt="{{content.title}} logo"
           fill priority>
    </div>
  </mat-card-header>

  <mat-divider class="card-divider"/>

  <mat-card-content class="card-content">
    <p class="mat-title-medium card-content-title">
      {{ content.title | translate }}
    </p>
    @if (content.subtitle) {
      <p class="mat-body-medium card-content-subtitle">
        {{ content.subtitle | translate }}
      </p>
    }
    @if (content.body) {
      <ng-container *ngComponentOutlet="content.body.component; inputs: content.body.inputs;"/>
    }

    @if (hasAnyAmount()) {
      <div class="action-container">
        @if (content.customersAmount !== null) {
          <div class="action-item">
            <div class="card-action-amount-container">
              <p class="card-action-amount card-action-amount-border">{{ content.customersAmount }}</p>
            </div>
            <div>
              <a class="card-action-type" [routerLink]="'/' + LipoRouteEnum.CUSTOMER">{{ 'customers' | translate }}</a>
            </div>
          </div>
        }

        @if (content.systemsAmount !== null) {
          <div class="action-item">
            <div class="card-action-amount-container">
              <p class="card-action-amount card-action-amount-border">{{ content.systemsAmount }}</p>
            </div>
            <div>
              <a class="card-action-type" [routerLink]="'/' + LipoRouteEnum.SYSTEM">{{ 'systems' | translate }}</a>
            </div>
          </div>
        }

        @if (content.licencesAmount !== null) {
          <div class="action-item">
            <div class="card-action-amount-container">
              <p class="card-action-amount card-action-amount-border">{{ content.licencesAmount }}</p>
            </div>
            <div>
              <a class="card-action-type" [routerLink]="'/' + LipoRouteEnum.LICENCE">{{ 'licenses' | translate }}</a>
            </div>
          </div>
        }

        @if (content.productsAmount !== null) {
          <div class="action-item">
            <div class="card-action-amount-container">
              <p class="card-action-amount card-action-amount-border">{{ content.productsAmount }}</p>
            </div>
            <div>
              <a class="card-action-type" [routerLink]="'/' + LipoRouteEnum.PRODUCT">{{ 'products' | translate }}</a>
            </div>
          </div>
        }

        @if (content.tenantsAmount !== null) {
          <div class="action-item">
            <div class="card-action-amount-container">
              <p class="card-action-amount card-action-amount-border">{{ content.tenantsAmount }}</p>
            </div>
            <div>
              <a class="card-action-type card-action-type-no-route">{{ 'tenants' | translate }}</a>
            </div>
          </div>
        }

        @if (content.usersAmount !== null) {
          <div class="action-item">
            <div class="card-action-amount-container">
              <p class="card-action-amount card-action-amount-border">{{ content.usersAmount }}</p>
            </div>
            <div>
              <a class="card-action-type card-action-type-no-route">{{ 'users' | translate }}</a>
            </div>
          </div>
        }
      </div>
    }
  </mat-card-content>
</mat-card>

