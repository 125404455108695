<div class="overview-container">
  <div class="overview-header">
    <div>
      <ngx-skeleton-loader count="1" appearance="line"
                           [theme]="{ height: '32px', width: '150px'}"></ngx-skeleton-loader>
    </div>
    <div class="overview-header-actions">
      <ngx-skeleton-loader count="1"
                           [theme]="{ 'border-radius': '24px', width: '135px', height: '40px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="line"
                           [theme]="{ height: '40px', width: '200px'}"></ngx-skeleton-loader>
    </div>
  </div>

  <div class="overview-body">
    <div class="overview-body-controls">
      @for (loader of Array(8).fill(0); track $index) {
        <ngx-skeleton-loader count="1" appearance="line"
                             [theme]="{height: '240px', width: '260px', 'border-radius': '12px'}"></ngx-skeleton-loader>
      }
    </div>
  </div>

</div>
