import {CustomerOverviewModel} from "../components/models/customer-overview.model";
import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";

export class CustomerOverviewMapper {
  public static toLipoCard(customerOverviewModel: CustomerOverviewModel): LipoCardModel {
    return new LipoCardModel({
        id: customerOverviewModel.id,
        route: {commands: [LipoRouteEnum.CUSTOMER, customerOverviewModel.id]},
        title: customerOverviewModel.name,
        subtitle: customerOverviewModel.phone,
        logoUrl: customerOverviewModel.logoUrl,
        systemsAmount: customerOverviewModel.systems.length,
        usersAmount: customerOverviewModel.users.length,
      }
    );
  }
}
