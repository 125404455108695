import {UserServiceModel} from "../../../user/services/models/user-service.model";
import {SystemServiceModel} from "../../../system/services/models/system-service.model";

export class CustomerOverviewModel {
  constructor(
    public id: number | null,
    public name: string,
    public phone: string,
    public logoUrl: string | null,
    public systems: SystemServiceModel[] = [],
    public users: UserServiceModel[] = []
  ) {
  }
}
