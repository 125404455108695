import {SystemServiceModel} from "../services/models/system-service.model";
import {SystemDetailModel} from "../components/models/system-detail.model";
import {SystemOverviewModel} from "../services/models/system-overview.model";
import {TenantServiceMapper} from "../../tenant/mappers/tenant-service.mapper";

export class SystemServiceMapper {

  public static toSystemDetailModel(systemServiceModel: SystemServiceModel): SystemDetailModel {
    return new SystemDetailModel(
      systemServiceModel.sapSystemId,
      systemServiceModel.name,
      systemServiceModel.hardwareKey,
      systemServiceModel.initialDate,
      systemServiceModel.isActive,
      systemServiceModel.isActive ? 'active' : 'idle',
      systemServiceModel.customer,
      systemServiceModel.tenants.map(TenantServiceMapper.toTenantDetailModel),
      systemServiceModel.identity,
      systemServiceModel.logo,
      systemServiceModel.id,
      systemServiceModel.basePath,
      systemServiceModel.dbPassword,
      systemServiceModel.dbUserName,
      systemServiceModel.sapServerName,
      systemServiceModel.sapServerType,
      systemServiceModel.sapLicServerName,
      systemServiceModel.sapServiceLayerName
    )
  }

  public static toSystemOverviewModel(systemServiceModel: SystemServiceModel): SystemOverviewModel {
    return new SystemOverviewModel(
      systemServiceModel.id,
      systemServiceModel.name,
      systemServiceModel.hardwareKey,
      systemServiceModel.logo,
      systemServiceModel.tenants
    )
  }
}
