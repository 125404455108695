import {PartnerOverviewModel} from "../components/models/partner-overview.model";
import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";

export class PartnerCardMapper {
  public static toLipoCardModel(partnerOverviewModel: PartnerOverviewModel): LipoCardModel {
    return new LipoCardModel({
      id: partnerOverviewModel.id,
      route: {commands: [LipoRouteEnum.PARTNER, partnerOverviewModel.id]},
      title: partnerOverviewModel.name,
      logoUrl: partnerOverviewModel.logoUrl,
      licencesAmount: partnerOverviewModel.licenses.length,
      customersAmount: partnerOverviewModel.customers.length,
      systemsAmount: partnerOverviewModel.systems.length,
    });
  }
}
