import {Type} from "@angular/core";
import {LipoTableModel} from "./lipo-table.model";

export class LipoDynamicComponentModel {
  public uuid?: string;
  public component?: Type<any> | LipoTableModel<any>;
  public inputs?: Record<string, unknown>;

  static build(options: {
    uuid?: string;
    component?: Type<any> | LipoTableModel<any>;
    inputs?: { [key: string]: any };
  } = {}) {
    const instance = new LipoDynamicComponentModel();
    instance.uuid = options.uuid;
    instance.component = options.component;
    instance.inputs = options.inputs;
    return instance;
  }
}
