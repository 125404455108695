@if (keycloak.isLoggedIn()) {
  <mat-sidenav-container autosize>
    <mat-sidenav
      class="sidenav"
      opened
      mode="side"
      [fixedInViewport]="true"
      [disableClose]="true"
      [style.max-width.px]="sideNavOpened ? 240 : 72"
    >
      <du-sidenav [(opened)]="sideNavOpened" (openedChange)="setSideNav($event)"/>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content" [class.sidenav-padding]="!landingPage">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
} @else {
  <router-outlet></router-outlet>
}

