export class LipoButton {
  constructor(
    public text: string,
    public onClick: () => Promise<any>,
    public className?: string,
    public icon?: string,
    public iconFontSet: string = "material-symbols-outlined",
  ) {
  }

  /**
   * Constructs an array of LipoButton objects from the provided button configurations.
   *
   * @param {...Object} buttons - A list of button configuration objects.
   * @param {string} buttons[].text - The text to display on the button.
   * @param {function} buttons[].onClick - An asynchronous function to execute when the button is clicked.
   * @param {string} [buttons[].className] - An optional CSS class name to apply to the button.
   * @param {string} [buttons[].icon] - An optional icon to display on the button.
   * @param {string} [buttons[].iconFontSet] - An optional font set for the icon.
   * @return {LipoButton[]} An array of LipoButton instances built from the configuration objects.
   */
  static build(...buttons: { text: string, onClick: () => Promise<any>, className?: string, icon?:string , iconFontSet?: string}[]): LipoButton[] {
    return buttons.map(item => new LipoButton(item.text, item.onClick, item.className, item.icon, item.iconFontSet));
  }
}
