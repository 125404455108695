import {KeycloakOptions} from "keycloak-angular";

export function keycloakSetup(issuer: string, realm: string, clientId: string): KeycloakOptions {
  return {
    config: {
      url: issuer,
      realm: localStorage.getItem('realm') || realm,
      clientId: clientId,
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
    },
    bearerPrefix: 'Bearer',
    enableBearerInterceptor: true,
  }
}
