import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {forkJoin, Observable} from "rxjs";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {ProductServiceModel} from "./models/product-service.model";

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {

  private readonly productEndpoint: string;
  private readonly api: string;

  constructor(
    private _http: HttpClient,
    _config: ConfigurationService
  ) {
    let config = _config.configuration;
    this.productEndpoint = config.api.endpoints.product;
    this.api = config.api.backendUrl;
  }

  createProduct(product: ProductServiceModel): Observable<ProductServiceModel> {
    return this._http.post<ProductServiceModel>(`${this.api}/${this.productEndpoint}`, product);
  }

  getProducts(): Observable<ProductServiceModel[]> {
    return this._http.get<ProductServiceModel[]>(`${this.api}/${this.productEndpoint}`);
  }

  getProductsByIds(productIds: number[]): Observable<ProductServiceModel[]> {
    const productObservables = productIds.map(id =>
      this._http.get<ProductServiceModel>(`${this.api}/${this.productEndpoint}/${id}`)
    );

    return forkJoin(productObservables);
  }
  getProduct(id: number): Observable<ProductServiceModel> {
    return this._http.get<ProductServiceModel>(`${this.api}/${this.productEndpoint}/${id}`);
  }

  updateProduct(product: ProductServiceModel): Observable<ProductServiceModel> {
    return this._http.put<ProductServiceModel>(`${this.api}/${this.productEndpoint}/${product.id}`, product);
  }

  deleteProduct(id: number): Observable<void> {
    return this._http.delete<void>(`${this.api}/${this.productEndpoint}/${id}`);
  }
}
