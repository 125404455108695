import {Component, Input} from '@angular/core';
import {ProductOverviewModel} from "../models/product-overview.model";

@Component({
  selector: 'du-product-overview-card-body',
  standalone: true,
  imports: [],
  templateUrl: './product-overview-card-body.component.html',
  styleUrl: './product-overview-card-body.component.scss'
})
export class ProductOverviewCardBodyComponent {
  @Input() productOverviewModel?: ProductOverviewModel;
}
