import {PartnerServiceModel} from "../services/models/partner-service.model";
import {PartnerOverviewModel} from "../components/models/partner-overview.model";
import {PartnerDetailModel} from "../components/models/partner-detail.model";
import {CustomerServiceMapper} from "../../customer/mappers/customer-service.mapper";
import {SystemServiceMapper} from "../../system/mappers/system-service.mapper";

export class PartnerServiceMapper {
  public static toPartnerOverviewMapper(partnerServiceModel: PartnerServiceModel): PartnerOverviewModel {
    return new PartnerOverviewModel(
      partnerServiceModel.id,
      partnerServiceModel.name,
      partnerServiceModel.phone,
      partnerServiceModel.logo,
      partnerServiceModel.customers.map(CustomerServiceMapper.toCustomerOverviewModel),
      partnerServiceModel.customers.flatMap(x => x.systems.map(SystemServiceMapper.toSystemOverviewModel))
    )
  }

  public static toPartnerDetailModel(partnerServiceModel: PartnerServiceModel): PartnerDetailModel {
    return new PartnerDetailModel(
      partnerServiceModel.id,
      partnerServiceModel.name,
      partnerServiceModel.mail,
      partnerServiceModel.phone,
      partnerServiceModel.addresses,
      partnerServiceModel.customers.map(CustomerServiceMapper.toCustomerDetailModel),
      partnerServiceModel.logo
    )
  }
}
