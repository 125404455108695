import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, filter} from 'rxjs';

export interface BreadcrumbItem {
  label: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<BreadcrumbItem[]>([]);
  public breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  constructor(private router: Router) {
    this.generateBreadcrumbs();

    this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.generateBreadcrumbs();
        });
  }

  private generateBreadcrumbs(): void {
    const segments = this.router.url.split('/').filter(segment => segment);
    const breadcrumbs: BreadcrumbItem[] = [];

    for (let i = 0; i < segments.length; i += 2) {
      const label = segments[i];
      const url = '/' + segments.slice(0, i + 2).join('/');

      breadcrumbs.push({
        label,
        url,
      });
    }

    this.breadcrumbsSubject.next(breadcrumbs);
  }
}
