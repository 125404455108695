export function fixUmlauts(value: string): string {
  return value.replace(/[äöüß ]/g, (match) => {
    switch (match) {
      case 'ä':
        return 'ae';
      case 'ö':
        return 'oe';
      case 'ü':
        return 'ue';
      case 'ß':
        return 'ss';
      case ' ':
        return '-';
      default:
        return match;
    }
  });
}
