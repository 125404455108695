import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogModule, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {TranslateModule} from "@ngx-translate/core";
import {TitleCasePipe, UpperCasePipe} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";
import {MatListOption, MatSelectionList} from "@angular/material/list";

@Component({
  selector: 'du-tenant-app-setting-selection-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    TranslateModule,
    MatSelectionList,
    UpperCasePipe,
    MatButton,
    MatDialogClose,
    MatDialogTitle,
    TitleCasePipe,
    MatListOption
  ],
  templateUrl: './tenant-app-setting-selection-dialog.component.html',
  styleUrl: './tenant-app-setting-selection-dialog.component.scss'
})
export class TenantAppSettingSelectionDialogComponent {
  readonly dialogRef = inject(MatDialogRef<TenantAppSettingSelectionDialogComponent>);
  readonly data = inject<
    {
      availableTenantAppSettings: TenantAppSettingsDetailModel[],
      selectedTenantAppSettings: TenantAppSettingsDetailModel[]
    }>(MAT_DIALOG_DATA);

  isSelected(availableItem: TenantAppSettingsDetailModel): boolean {
    return this.data.selectedTenantAppSettings.some(selectedItem => selectedItem.appName === availableItem.appName);
  }

  onSelectionChange(options: MatListOption[]): void {
    this.data.selectedTenantAppSettings =
      this.data.availableTenantAppSettings.filter(
        item => options.some(option => option.value.appName === item.appName)
      );
  }

  onDialogCloseWithData(): TenantAppSettingsDetailModel[] {
    return this.data.selectedTenantAppSettings;
  }
}
