import {Component} from '@angular/core';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";

@Component({
  selector: 'du-lipo-overview-skeleton',
  standalone: true,
  imports: [
    NgxSkeletonLoaderModule
  ],
  templateUrl: './lipo-overview-skeleton.component.html',
  styleUrl: './lipo-overview-skeleton.component.scss'
})
export class LipoOverviewSkeletonComponent {
  protected readonly Array = Array;
}
