import {Component, inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {CdkTrapFocus} from "@angular/cdk/a11y";
import {Clipboard} from '@angular/cdk/clipboard';


@Component({
  selector: 'du-tenant-it-instructions-dialog',
  standalone: true,
  templateUrl: './tenant-it-instructions-dialog.component.html',
  styleUrls: ['./tenant-it-instructions-dialog.component.scss'],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatTableModule,
    TranslateModule,
    CommonModule,
    CdkTrapFocus,
  ],
})
export class TenantItInstructionsDialogComponent implements OnInit {
  data = inject(MAT_DIALOG_DATA) as {
    ipWhitelist: string[];
    externalPort?: string;
    internalPort?: string;
    tenants: {
      name: string;
      internalPort: string
      externalPort: string
    }[];
    showTenantNames: boolean
  };

  private readonly _clipboard = inject(Clipboard);
  private readonly translate = inject(TranslateService);


  ipWhitelist!: string[];
  internalPort?: string;
  externalPort?: string;
  tenants!: { name: string; externalPort: string; internalPort:string }[];
  showTenantNames!: boolean;
  hasTenants: boolean = false;

  ngOnInit(): void {
    this.ipWhitelist = this.data.ipWhitelist;
    this.internalPort = this.data.internalPort;
    this.externalPort = this.data.externalPort;
    this.tenants = this.data.tenants;
    this.showTenantNames = this.data.showTenantNames;
    this.hasTenants = this.tenants?.length > 0;
  }

  copyToClipboard(): void {
    const translationKeys = [
      'itInstructions.title',
      'itInstructions.text',
      'itInstructions.ipWhiteList',
      'itInstructions.internalPort',
      'itInstructions.externalPort',
      'tenants',
    ];

    this.translate.get(translationKeys).subscribe(translations => {
      const textToCopy = [
        translations['itInstructions.title'],
        translations['itInstructions.text'],
        `${translations['itInstructions.ipWhiteList']}:\n${this.ipWhitelist.join('\n')}`,
        this.internalPort ? `${translations['itInstructions.internalPort']}: ${this.internalPort}` : '',
        this.externalPort ? `${translations['itInstructions.externalPort']}: ${this.externalPort}` : '',
        this.showTenantNames
          ? `${translations['tenants']}:\n` +
          this.tenants
            .map(
              tenant =>
                `${tenant.name} - ${translations['itInstructions.internalPort']}: ${tenant.internalPort}, ${translations['itInstructions.externalPort']}: ${tenant.externalPort}`
            )
            .join('\n')
          : '',
      ]
        .filter(Boolean)
        .join('\n\n');

      this._clipboard.copy(textToCopy);
    });
  }

}
