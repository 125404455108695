  <div class="details-container">
    <div class="details-header">
      <div>
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '44px', width: '44px', 'margin-right': '12px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '32px', width: '150px'}"></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader count="3" appearance="circle" [theme]="{height: '40px', width: '40px'}"></ngx-skeleton-loader>
      </div>
    </div>

    <div class="details-body">
      <div class="details-body-title">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '38px', width: '120px'}"></ngx-skeleton-loader>
      </div>

      <div class="details-body-controls">
        @for (loader of  Array(6).fill(0); track $index) {
          <ngx-skeleton-loader count="1" appearance="line"
                               [theme]="{height: '52px', width: '200px'}"></ngx-skeleton-loader>
        }
      </div>

      <div class="details-body-tab">

        <div class="details-body-tab-header">
          @for (loader of  Array(2).fill(0); track $index) {
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '28px', width: '64px'}"></ngx-skeleton-loader>
          }
        </div>

        <div class="details-body-table">
          <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="3" appearance="line"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
        </div>

      </div>

    </div>


  </div>
