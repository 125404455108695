import {TenantAppSettingsDetailModel} from "../components/models/tenant-app-settings-detail.model";
import {TenantAppSettingsServiceModel} from "../services/models/tenant-app-settings-service.model";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";

export class TenantAppSettingsDetailMapper {
  public static toTenantAppSettingsServiceModel(tenantAppSettingsDetailModel: TenantAppSettingsDetailModel): TenantAppSettingsServiceModel {
    return {
      appName: tenantAppSettingsDetailModel.appName,
      description: tenantAppSettingsDetailModel.description,
      attributes: tenantAppSettingsDetailModel.attributes,
      id: tenantAppSettingsDetailModel.id,
    }
  }

  public static toLipoDetailModel(tenantAppSettingsDetailModel: TenantAppSettingsDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      tenantAppSettingsDetailModel.appName ?? 'app_settings',
      tenantAppSettingsDetailModel.id ? `#${tenantAppSettingsDetailModel.id}` : null,
      null,
      formModel,
      tabs
    )
  }
}
