import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {SidenavComponent} from "./sidenav/sidenav.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {KeycloakService} from "keycloak-angular";
import {filter} from "rxjs";
import {LipoRouteEnum} from "./shared/enums/lipo-route.enum";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    MatSidenavModule,
    SidenavComponent,
    RouterOutlet
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  private readonly lSSideNavProperty: string = 'sideNavOpened';

  landingPage: boolean = false;
  sideNavOpened: boolean;

  constructor(
    private router: Router,
    protected keycloak: KeycloakService,
  ) {
    this.sideNavOpened = this.manageLocalStorageSideNav();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.landingPage = event.url === `/${LipoRouteEnum.HOME}`;
      });
  }

  manageLocalStorageSideNav(): boolean {
    let localStorageSideNav = localStorage.getItem(this.lSSideNavProperty);

    if (localStorageSideNav === null) {
      localStorageSideNav = 'false';
      localStorage.setItem(this.lSSideNavProperty, localStorageSideNav);
    }

    return localStorageSideNav === 'true';
  }

  setSideNav(opened: boolean) {
    this.sideNavOpened = opened
    localStorage.setItem(this.lSSideNavProperty, `${opened}`);
  }
}
