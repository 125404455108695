import {Component} from '@angular/core';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'du-lipo-detail-skeleton',
  standalone: true,
  imports: [
    NgxSkeletonLoaderModule,
    NgForOf
  ],
  templateUrl: './lipo-detail-skeleton.component.html',
  styleUrl: './lipo-detail-skeleton.component.scss'
})
export class LipoDetailSkeletonComponent {
  protected readonly Array = Array;
}
