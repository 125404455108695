import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";

export class TenantAppSettingsDetailModel implements LipoModelInterface {

  constructor(
    public appName: string | null = null,
    public description: string = '',
    public attributes: any | null = null,
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}

/**
 * Represents the settings for mobile logistics, including company policies,
 * subscription information, and navigation bar customization options.
 * @property {string} Subscriptions - must be '|' seperated
 */
export class MobileLogisticsSettingsModel {
  constructor(
    public CompanyPolicies: CompanyPoliciesModel = new CompanyPoliciesModel(),
    public Subscriptions: string = '',
    public NavBarColor: string = '',
    public NavBarTextAccentColor: string = '',
    public NavBarTextColor: string = '',
  ) {}
}

export class CompanyPoliciesModel {
  constructor(
    public HideQuantities: boolean = false,
    public PackagingItemAllocation: boolean = false,
    public ItemScanningCountingEnabled: boolean = false,
    public ItemScanningPicklistEnabled: boolean = false,
    public ItemScanningGoodsReceiptEnabled: boolean = false,
  ) {}
}
