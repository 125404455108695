import {TenantServiceModel} from "../../../tenant/services/models/tenant-service.model";

export class SystemOverviewModel {
  constructor(
    public id: number | null,
    public name: string,
    public hardwareKey: string | null,
    public logo: string | null,
    public tenants: TenantServiceModel[]
  ) { }
}
