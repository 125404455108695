import {Type} from "@angular/core";
import {NavigationExtras} from "@angular/router";

/**
 * Interface representing the options for a LipoCardModel.
 *
 * @property {number | null} id - The unique identifier for the card. Can be null.
 * @property {{commands: any[], extras?: NavigationExtras} | undefined} route - The route configuration for navigation, consisting of commands and optional extras.
 * @property {string} title - The title of the card.
 * @property {string | null} [subtitle] - The optional subtitle of the card. Can be null.
 * @property {{component: Type<any>, inputs: Record<string, unknown>} | null} [body] - The optional body component of the card, including the component type and its inputs. Can be null.
 * @property {string | null} [logoUrl] - The optional URL of the logo to be displayed on the card. Can be null.
 * @property {number | null} [licencesAmount] - The optional amount of licenses associated with the card. Can be null.
 * @property {number | null} [customersAmount] - The optional amount of customers associated with the card. Can be null.
 * @property {number | null} [systemsAmount] - The optional amount of systems associated with the card. Can be null.
 * @property {number | null} [productsAmount] - The optional amount of products associated with the card. Can be null.
 * @property {number | null} [tenantsAmount] - The optional amount of tenants associated with the card. Can be null.
 * @property {number | null} [usersAmount] - The optional amount of users associated with the card. Can be null.
 */
interface LipoCardModelOptions {
  id: number | null;
  route: {commands: any[], extras?: NavigationExtras} | undefined;
  title: string;
  subtitle?: string | null;
  body?: {
    component: Type<any>,
    inputs: Record<string, unknown>
  } | null;
  logoUrl?: string | null;
  licencesAmount?: number | null;
  customersAmount?: number | null;
  systemsAmount?: number | null;
  productsAmount?: number | null;
  tenantsAmount?: number | null;
  usersAmount?: number | null;
}

export class LipoCardModel {
  public id: number | null;
  public route: {commands: any[], extras?: NavigationExtras} | undefined;
  public title: string;
  public subtitle: string | null;
  public body: {
    component: Type<any>,
    inputs: { [key: string]: any }
  } | null;
  public logoUrl: string | null;
  public licencesAmount: number | null;
  public customersAmount: number | null;
  public systemsAmount: number | null;
  public productsAmount: number | null;
  public tenantsAmount: number | null;
  public usersAmount: number | null;
  [key: string]: any;

  constructor(options: LipoCardModelOptions) {
    this.id = options.id;
    this.route = options.route;
    this.title = options.title;
    this.subtitle = options.subtitle ?? null;
    this.body = options.body ?? null;
    this.logoUrl = options.logoUrl ?? null;
    this.licencesAmount = options.licencesAmount ?? null;
    this.customersAmount = options.customersAmount ?? null;
    this.systemsAmount = options.systemsAmount ?? null;
    this.productsAmount = options.productsAmount ?? null;
    this.tenantsAmount = options.tenantsAmount ?? null;
    this.usersAmount = options.usersAmount ?? null;
  }
}
