@if (licenseOverviewModel) {
  <table class="license-overview-card-table">
    <tr>
      <th>{{ 'tenant' | translate }}</th>
      <td>{{ licenseOverviewModel.tenant.sapCompanyDB }}</td>
    </tr>
    <tr>
      <th>{{ 'purchase_date' | translate }}</th>
      <td>{{ licenseOverviewModel.purchaseDate.toDate() | date }}</td>
    </tr>
    <tr>
      <th>{{ 'expiry_date' | translate }}</th>
      <td>{{ licenseOverviewModel.expiryDate.toDate() | date }}</td>
    </tr>
  </table>
}
