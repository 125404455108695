import {Route, Routes} from '@angular/router';
import {AuthGuardService} from "./keycloak/services/auth-guard.service";
import {PartnerOverviewComponent} from "./partner/components/partner-overview/partner-overview.component";
import {PartnerDetailComponent} from "./partner/components/partner-detail/partner-detail.component";
import {LipoRouteEnum} from "./shared/enums/lipo-route.enum";
import {LipoRoutesDataModel} from "./shared/models/lipo-routes-data.model";
import {KeycloakRoleEnum} from "./shared/enums/keycloak-role.enum";
import {SystemDetailComponent} from "./system/components/system-detail/system-detail.component";
import {CustomerOverviewComponent} from "./customer/components/customer-overview/customer-overview.component";
import {CustomerDetailComponent} from "./customer/components/customer-detail/customer-detail.component";
import {SystemOverviewComponent} from "./system/components/system-overview/system-overview.component";
import {TenantDetailComponent} from "./tenant/components/tenant-detail/tenant-detail.component";
import {ProductOverviewComponent} from "./product/components/product-overview/product-overview.component";
import {ProductDetailComponent} from "./product/components/prodcut-detail/product-detail.component";
import {
  ProductPriceDetailComponent
} from "./productPrice/components/product-price-detail/product-price-detail.component";
import {LicenceOverviewComponent} from "./licence/components/licence-overview/licence-overview.component";
import {UserDetailComponent} from "./user/components/user-detail/user-detail.component";
import {TenantTokenDetailComponent} from "./tenant/components/tenant-token-detail/tenant-token-detail.component";
import {
  TenantUserMappingDetailComponent
} from "./tenant/components/tenant-user-mapping-detail/tenant-user-mapping-detail.component";
import {
  TenantAppSettingsDetailComponent
} from "./tenant/components/tenant-app-setting-detail/tenant-app-settings-detail.component";
import {LipoLandingComponent} from "./shared/components/lipo-landing/lipo-landing.component";
import {RealmChooserComponent} from "./keycloak/realmChooser/realm-chooser.component";
import {routeRoleConfig} from "./shared/routeRoleConfig";

const partnerRoute: Route =
  {
    path: LipoRouteEnum.PARTNER,
    children: [
      {
        path: '',
        component: PartnerOverviewComponent,
        data: {roles: routeRoleConfig.partner} as LipoRoutesDataModel,
      },
      {
        path: 'new',
        component: PartnerDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.partner
        } as LipoRoutesDataModel
      },
      {
        path: ':id',
        component: PartnerDetailComponent,
        data: {
          isCreateItem: false,
          roles: routeRoleConfig.partner
        } as LipoRoutesDataModel
      },
    ]
  }

const licenseRoute: Route =
  {
    path: LipoRouteEnum.LICENCE,
    children: [
      {
        path: '',
        component: LicenceOverviewComponent,
        data: {roles: routeRoleConfig.license} as LipoRoutesDataModel,
      },
    ]
  }

const userMappingRoute: Route =
  {
    path: LipoRouteEnum.USER_MAPPING,
    children: [
      {
        path: '',
        component: TenantUserMappingDetailComponent,
        data: {roles: routeRoleConfig.userMapping} as LipoRoutesDataModel
      },
      {
        path: 'new',
        component: TenantUserMappingDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.userMapping
        } as LipoRoutesDataModel
      },
      {
        path: ':userMappingId',
        component: TenantUserMappingDetailComponent,
        data: {
          isCreateItem: false,
          roles: routeRoleConfig.userMapping
        } as LipoRoutesDataModel
      }
    ]
  }

const tokenRoute: Route =
  {
    path: LipoRouteEnum.TOKEN,
    children: [
      {
        path: '',
        component: TenantTokenDetailComponent,
        data: {roles: routeRoleConfig.token} as LipoRoutesDataModel
      },
      {
        path: 'new',
        component: TenantTokenDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.token
        } as LipoRoutesDataModel
      },
    ]
  }

const appSettingRoute: Route =
  {
    path: LipoRouteEnum.APP_SETTINGS,
    children: [
      {
        path: '',
        component: TenantAppSettingsDetailComponent,
        data: {roles: routeRoleConfig.appSetting} as LipoRoutesDataModel
      },
      {
        path: 'new',
        component: TenantAppSettingsDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.appSetting
        } as LipoRoutesDataModel
      },
      {
        path: ':tenantAppSettingId',
        component: TenantAppSettingsDetailComponent,
        data: {
          isCreateItem: false,
          roles: routeRoleConfig.appSetting
        } as LipoRoutesDataModel
      }
    ]
  }

const tenantRoute: Route =
  {
    path: LipoRouteEnum.TENANT,
    children: [
      {
        path: 'new',
        component: TenantDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.tenant
        } as LipoRoutesDataModel
      },
      {
        path: ':tenantId',
        children: [
          {
            path: '',
            component: TenantDetailComponent,
            data: {
              isCreateItem: false,
              roles: routeRoleConfig.tenant
            } as LipoRoutesDataModel
          },
          tokenRoute,
          userMappingRoute,
          appSettingRoute
        ]
      },
    ]
  }

const systemRoute: Route =
  {
    path: LipoRouteEnum.SYSTEM,
    children: [
      {
        path: '',
        component: SystemOverviewComponent,
        data: {roles: routeRoleConfig.system} as LipoRoutesDataModel,
      },
      {
        path: 'new',
        component: SystemDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.system
        } as LipoRoutesDataModel
      },
      {
        path: ':systemId',
        children: [
          {
            path: '',
            component: SystemDetailComponent,
            data: {
              isCreateItem: false,
              roles: routeRoleConfig.system
            } as LipoRoutesDataModel
          },
          tenantRoute,
        ]
      }
    ]
  }

const productPriceRoute: Route =
  {
    path: LipoRouteEnum.PRODUCT_PRICE,
    children: [
      {
        path: '',
        component: ProductPriceDetailComponent,
        data: {roles: routeRoleConfig.productPrice} as LipoRoutesDataModel,
      },
      {
        path: 'new',
        component: ProductPriceDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.productPrice
        } as LipoRoutesDataModel,
      },
      {
        path: ':productPriceId',
        component: ProductPriceDetailComponent,
        data: {
          isCreateItem: false,
          roles: routeRoleConfig.productPrice
        } as LipoRoutesDataModel,
      },
    ]
  }

const productRoute: Route =
  {
    path: LipoRouteEnum.PRODUCT,
    children: [
      {
        path: '',
        component: ProductOverviewComponent,
        data: {roles: [...routeRoleConfig.product, KeycloakRoleEnum.CUSTOMER]} as LipoRoutesDataModel,
      },
      {
        path: 'new',
        component: ProductDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.product
        } as LipoRoutesDataModel
      },
      {
        path: ':productId',
        children: [
          {
            path: '',
            component: ProductDetailComponent,
            data: {
              isCreateItem: false,
              roles: [...routeRoleConfig.product, KeycloakRoleEnum.CUSTOMER]
            } as LipoRoutesDataModel,
          },
          productPriceRoute,
        ]
      },
    ]
  }

const userRoute: Route =
  {
    path: LipoRouteEnum.USER,
    children: [
      {
        path: '',
        component: UserDetailComponent,
        data: {roles: routeRoleConfig.userRole} as LipoRoutesDataModel
      },
      {
        path: 'new',
        component: UserDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.userRole
        } as LipoRoutesDataModel,
      },
      {
        path: ':userId',
        component: UserDetailComponent,
        data: {
          isCreateItem: false,
          roles: routeRoleConfig.userRole
        } as LipoRoutesDataModel,
      },
    ]
  }

const customerRoute: Route =
  {
    path: LipoRouteEnum.CUSTOMER,
    children: [
      {
        path: '',
        component: CustomerOverviewComponent,
        data: {roles: routeRoleConfig.customer} as LipoRoutesDataModel,
      },
      {
        path: 'new',
        component: CustomerDetailComponent,
        data: {
          isCreateItem: true,
          roles: routeRoleConfig.customer
        } as LipoRoutesDataModel
      },
      {
        path: ':customerId',
        children: [
          {
            path: '',
            component: CustomerDetailComponent,
            data: {
              isCreateItem: false,
              roles: routeRoleConfig.customer
            } as LipoRoutesDataModel
          },
          userRoute,
        ]
      },
    ]
  }

export const routes: Routes = [
  {
    path: 'realm',
    component: RealmChooserComponent,
  },
  {
    path: LipoRouteEnum.HOME,
    component: LipoLandingComponent,
    canActivate: [AuthGuardService],
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      partnerRoute,
      customerRoute,
      systemRoute,
      productRoute,
      licenseRoute,
    ]
  },
];
