import {Component} from '@angular/core';
import {RealmService} from "../services/realm.service";
import {MatButtonModule} from "@angular/material/button";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {RegexPatterns} from "../../utils/regex-patterns";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";
import {SnackbarService} from "../../shared/services/snackbar.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatCard, MatCardActions, MatCardContent, MatCardHeader} from "@angular/material/card";

@Component({
  selector: 'du-realmChooser',
  standalone: true,
  imports: [
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatCard,
    MatCardContent,
    MatCardActions,
    MatCardHeader,
    NgOptimizedImage,
    TranslateModule,
  ],
  templateUrl: './realm-chooser.component.html',
  styleUrl: './realm-chooser.component.scss'
})
export class RealmChooserComponent{
  realmForm: FormGroup;

  constructor(
    protected realmService: RealmService,
    private formBuilder: FormBuilder,
    private keyCloakService: KeycloakService,
    private router: Router,
    private snackBarService: SnackbarService,
    private translate: TranslateService,
  ) {
    this.checkIfAllowed();
    this.realmForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(RegexPatterns.Email)]]
    });
  }

  private checkIfAllowed(){
    if (this.keyCloakService.isLoggedIn()) {
      this.router.navigate([LipoRouteEnum.HOME]).then(() =>
        this.snackBarService.Warning(this.translate.instant('snackbar.already_signed_in'))
      );
    }
  }

  async onSubmit() {
    if (this.realmForm.valid) {
      const email = this.realmForm.get('email')?.value;
      this.realmService.getRealmFromMail(email).subscribe({
        next: realm => {
          this.realmService.setRealmAndLogin(realm.realm, email);
        },
        error: () => {
          this.snackBarService.Warning(
            this.translate.instant('snackbar.user_not_found')
          );
        }
      });
    }
  }

}
