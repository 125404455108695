import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";

export class UserDetailModel implements LipoModelInterface {
  constructor(
    public firstName: string | null = null,
    public lastName: string | null = null,
    public email: string = '',
    public sapUser: string | null = null,
    public keyCloakUserId: string | null = null,
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}

export class UserSaveDetailModel implements LipoModelInterface {
  constructor(
    public firstName: string | null = null,
    public lastName: string | null = null,
    public email: string = '',
    public sapUser: string | null = null,
    public keyCloakUserId: string | null = null,
  ) {
  }

  getId(): null {
    return null;
  }
}
