import {Component, OnInit} from '@angular/core';
import {BreadcrumbItem, BreadcrumbService} from "../../services/breadcrumb.service";
import {RouterLink, RouterLinkActive} from '@angular/router';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatIcon} from "@angular/material/icon";
import {MatListItem, MatNavList} from "@angular/material/list";

@Component({
  selector: 'du-lipo-breadcrumbs-menu',
  standalone: true,
  imports: [RouterLink, CommonModule, TranslateModule, MatIcon, MatNavList, MatListItem, RouterLinkActive],
  templateUrl: './lipo-breadcrumbs.component.html',
  styleUrl: './lipo-breadcrumbs.component.scss'
})
export class LipoBreadcrumbsComponent implements OnInit {
  breadcrumbs: BreadcrumbItem[] = [];

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbs$.subscribe((breadcrumbs: BreadcrumbItem[]) => {
      this.breadcrumbs = breadcrumbs;
    });
  }
}
