import {Injectable} from '@angular/core';
import {Configuration} from "./models/configuration.model";
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private _configuration: Configuration = {} as Configuration;

  constructor(private httpClient: HttpClient) {}

  async loadConfig(): Promise<void> {
    try {
      this.configuration = await lastValueFrom(this.httpClient.get<Configuration>('assets/config.json'));
    } catch (err: any) {
      console.error(`Error loading config.json: ${err.message}`);
      throw err;
    }
  }

  private set configuration(configuration: Configuration) {
    this._configuration = configuration;
  }

  get configuration(): Configuration {
    return this._configuration;
  }
}
