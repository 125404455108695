import {UserServiceModel} from "../services/models/user-service.model";
import {UserDetailModel} from "../components/models/user-detail.model";

export class UserServiceMapper {
  public static toUserDetailModel(userServiceModel: UserServiceModel): UserDetailModel {
    return new UserDetailModel(
      userServiceModel.firstName,
      userServiceModel.lastName,
      userServiceModel.email,
      userServiceModel.sapUser,
      userServiceModel.keyCloakUserId,
      userServiceModel.id
    );
  }
}
