<div class="dialog-body">
  @if (data.title) {
    <h1 mat-dialog-title class="mat-title-large">{{ data.title }}</h1>
  }
  @if (data.content) {
    <mat-dialog-content>
      <p class="mat-body">
        {{ data.content }}
      </p>
    </mat-dialog-content>
  }

  <mat-dialog-actions [align]="'end'">
    <button mat-button
            [mat-dialog-close]="false">{{
        data.cancelButtonText ? (data.cancelButtonText | uppercase) :
          ('button.cancel' | translate | uppercase)
      }}
    </button>
    @if (data.confirmButtonText) {
      <button mat-button [mat-dialog-close]="true">{{ data.confirmButtonText | uppercase }}</button>
    }
  </mat-dialog-actions>
</div>

