<div class="empty-container">
    <div class="empty-content">
      <div class="logo-container">
        <img ngSrc="assets/images/empty.png" alt="logo" fill priority/>
      </div>
      <div class="empty-text">
        <p class="mat-label-large">{{"no_entries_yet" | translate}}</p>
        <p class="mat-body-large">{{"create_entries_to_display_here" | translate}}</p>
      </div>
    </div>
</div>
