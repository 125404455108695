import {AppLicenseServiceModel} from "../services/models/app-license-view-service.model";
import {AppLicenseDetailModel} from "../components/models/app-license-detail.model";
import moment from "moment";

export class AppLicenseServiceMapper {
  public static toAppLicenseDetailModel(appLicenseServiceModel: AppLicenseServiceModel): AppLicenseDetailModel {
    return new AppLicenseDetailModel(
      moment(appLicenseServiceModel.purchaseDate),
      moment(appLicenseServiceModel.expiryDate),
      appLicenseServiceModel.customerId,
      appLicenseServiceModel.tenantId,
      appLicenseServiceModel.productId,
      appLicenseServiceModel.productPriceId,
      appLicenseServiceModel.isActive,
      appLicenseServiceModel.isActive ? 'active' : 'idle',
      appLicenseServiceModel.id
    )
  }
}
