<h1 mat-dialog-title class="mat-title-large">{{ 'app_settings' | translate | titlecase }}</h1>
<mat-dialog-content>
    <mat-selection-list (selectionChange)="onSelectionChange($event.source.selectedOptions.selected)">
      @for (tenantAppSetting of data.availableTenantAppSettings; track tenantAppSetting) {
        <mat-list-option [value]="tenantAppSetting" [selected]="isSelected(tenantAppSetting)">{{ tenantAppSetting.appName }}</mat-list-option>
      }
    </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button mat-button (click)="dialogRef.close()">{{'button.cancel' | translate | uppercase}}</button>
  <button mat-button [mat-dialog-close]="onDialogCloseWithData()" cdkFocusInitial>{{'button.apply' | translate | uppercase}}</button>
</mat-dialog-actions>
