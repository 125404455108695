import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {CdkTrapFocus} from "@angular/cdk/a11y";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'lipo-reset-password-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    MatIcon,
    CdkTrapFocus,
    MatTooltip,
  ],
  templateUrl: './lipo-reset-password-dialog.component.html',
  styleUrls: ['./lipo-reset-password-dialog.component.scss'],
})
export class LipoResetPasswordDialogComponent {
  resetPasswordForm!: FormGroup;
  showPassword: boolean = false;
  data = inject(MAT_DIALOG_DATA);

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<LipoResetPasswordDialogComponent>,
  ) {
    this.dialogRef.disableClose = true;
    this.setForm()
  }

  setForm() {
    this.resetPasswordForm = this.fb.group({
      newPassword: ['', [
        Validators.required
      ]],
      confirmPassword: ['', [
        Validators.required
      ]]
    });
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  onSave(): void {
    if (this.resetPasswordForm.valid && this.resetPasswordForm.value.newPassword === this.resetPasswordForm.value.confirmPassword) {
      this.dialogRef.close(this.resetPasswordForm.value.newPassword);
    } else {
      this.resetPasswordForm.get('confirmPassword')?.setErrors({ mismatch: true });
    }
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
