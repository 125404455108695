import {Component, OnInit} from '@angular/core';
import {MatCard, MatCardActions, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {KeycloakService} from "keycloak-angular";
import {KeycloakProfile} from "keycloak-js";
import {NgOptimizedImage} from "@angular/common";
import {navigationConfig} from "../../navigationConfig";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'du-lipo-landing',
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardActions,
    MatButton,
    MatCardContent,
    MatIcon,
    NgOptimizedImage,
    TranslateModule,
    RouterLink
  ],
  templateUrl: './lipo-landing.component.html',
  styleUrl: './lipo-landing.component.scss'
})
export class LipoLandingComponent implements OnInit {
  public profile?: KeycloakProfile;
  public profilePicture: string = 'assets/images/user_profile_placeholder.png';
  public bannerPicture: string = 'assets/images/background-landing.png';
  protected readonly navigation = navigationConfig;

  constructor(
    readonly _keycloakService: KeycloakService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this._keycloakService.loadUserProfile().then(profile => {
      this.profile = profile;
    });
  }

  isUserInRole(onlyRolesAllowed: string[]): boolean {
    if (onlyRolesAllowed.length === 0) {
      return true
    }
    return this._keycloakService.getUserRoles().some(role => onlyRolesAllowed.includes(role))
  }

  protected readonly TranslateModule = TranslateModule;

}
