@if (_progressService.isLoading()) {
  <du-lipo-overview-skeleton></du-lipo-overview-skeleton>
} @else {
  <div *ngIf="overviewModel" class="overview-header">
    <div class="overview-header-title">
      <div>
        <h1 class="mat-headline-small overview-title">
          {{ overviewModel.title | translate | titlecase }}
        </h1>
        <p class="mat-body-medium overview-subtitle">
          {{ overviewModel.cards.length }} {{ overviewModel.cards.length | i18nPlural: ('entry_map' | translate) }}
        </p>
      </div>
      <!-- TODO: Implement grid view  -->
      <div class="overview-header-flex-align-center" style="display: none">
        <mat-button-toggle-group class="overview-header-button-toggle dense-3" name="overview-view-type"
                                 [multiple]="false"
                                 hideSingleSelectionIndicator>
          <mat-button-toggle checked value="grid">
            <mat-icon aria-label="grid"
                      fontIcon="grid_view"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle disabled value="list">
            <mat-icon aria-label="list"
                      fontIcon="view_list"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

    </div>

    <div class="overview-header-filter">
      <!-- TODO: Implement filter function -->
      <div class="overview-header-flex-align-center chip-container">
      </div>
      <div class="overview-header-flex-align-center search-container">
        @for (button of overviewModel.buttons; track $index) {
          <button mat-stroked-button class="overview-header-add-button {{button.className}}"
                  (click)="button.onClick()">
            @if (button.icon) {
              <mat-icon class="button-icon" [fontSet]="button.iconFontSet">{{ button.icon }}</mat-icon>
            }
            {{ button.text | translate | uppercase }}
          </button>
        }
        <div class="dense-4">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" color="accent">
            <mat-label>{{ 'search' | translate }}</mat-label>
            <input matInput [(ngModel)]="searchInput" (input)="applyFilter()">
            <mat-icon matSuffix fontIcon="search"></mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  @if (overviewModel && overviewModel.cards.length > 0) {
    <div class="overview-body">
      <div class="card-container">
        @for (card of tableDataSource.filteredData; track card) {
          <du-lipo-card [disabled]="cardDisabled" [content]="card"></du-lipo-card>
        }
      </div>
    </div>
  } @else {
    <du-lipo-overview-empty></du-lipo-overview-empty>
  }
}
