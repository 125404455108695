import {AppLicenseSettingsConfiguration} from "../services/models/configuration.model";
import {FormGroup} from "@angular/forms";
import {
  CompanyPoliciesModel,
  MobileLogisticsSettingsModel
} from "../../tenant/components/models/tenant-app-settings-detail.model";

export function AppSettingsFormMapper(formGroup: FormGroup, appName: string | null, appSettings: AppLicenseSettingsConfiguration): any {
  switch (appName) {
    case appSettings.mobileLogistic.formKey: {
      let mobileLogisticControl = formGroup.get(`${appSettings.mobileLogistic.formKey}_base`)
      let mobileLogisticModel = mobileLogisticControl?.getRawValue() as MobileLogisticsSettingsModel

      mobileLogisticModel.Subscriptions = mobileLogisticControl?.get('Subscriptions')?.getRawValue().join('|')
      mobileLogisticModel.CompanyPolicies = formGroup.get(`${appSettings.mobileLogistic.formKey}_companyPolicy`)?.getRawValue() as CompanyPoliciesModel

      return mobileLogisticModel
    }
  }
}
