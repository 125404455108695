import {ProductPriceServiceModel} from "../services/models/product-price-service.model";
import {ProductPriceDetailModel} from "../components/models/product-price-detail.model";
import moment from "moment";

export class ProductPriceServiceMapper {
  public static toProductPriceDetailModel(productServiceModel: ProductPriceServiceModel): ProductPriceDetailModel {
    return new ProductPriceDetailModel(
      productServiceModel.name,
      productServiceModel.price,
      productServiceModel.currency,
      moment(productServiceModel.validFrom),
      moment(productServiceModel.validTo),
      productServiceModel.type,
      productServiceModel.id
    )
  }
}
