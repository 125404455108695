import {SystemSaveDetailModel} from "../components/models/system-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {SystemSaveServiceModel} from "../services/models/system-service.model";

export class SystemSaveDetailMapper {
  public static toLipoDetailSaveModel(system: SystemSaveDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      'system',
      '',
      null,
      formModel,
      tabs
    );
  }

  public static toSystemSaveServiceModel(systemDetailModel: SystemSaveDetailModel): SystemSaveServiceModel {
    return {
      name: systemDetailModel.name,
      hardwareKey: systemDetailModel.hardwareKey,
      customer: systemDetailModel.customer,
      identity: systemDetailModel.identity
      } as SystemSaveServiceModel;
  }
}
