import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {KeyCloakConfiguration} from "../../shared/services/models/configuration.model";
import {Observable} from "rxjs";
import {RealmModel} from "../models/realm.model";
import {keycloakSetup} from "../keycloakSetup";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";

@Injectable({
  providedIn: 'root'
})
export class RealmService{
  private readonly lSRealmProperty: string = 'realm';
  private readonly realmEndpoint: string;
  private readonly api: string;
  private readonly realmToken: string;
  private readonly keyCloakConfig: KeyCloakConfiguration;

  constructor(
    private router: Router,
    private keycloak: KeycloakService,
    private httpClient: HttpClient,
    _config: ConfigurationService,
  ) {
    let config = _config.configuration;
    this.realmEndpoint = config.api.endpoints.realm;
    this.api = config.api.backendUrl;
    this.realmToken = config.api.realmToken
    this.keyCloakConfig = config.keycloak;
  }

  async handleRealm() {
    let realm = this.getRealm();
    if (realm == null || realm.length == 0) {
      await this.router.navigate(['realm']);
    } else {
      await this.setRealmAndLogin(realm, undefined);
    }
  }

  getRealmFromMail(mail: string): Observable<RealmModel> {
    const headers = new HttpHeaders().set('x-api-key', this.realmToken);
    return this.httpClient.get<RealmModel>(
      `${this.api}/${this.realmEndpoint}/user?mail=${mail}`,
      { headers }
    );
  }

  async setRealmAndLogin(realm: string, mail: string | undefined) {
    try {
      let realmFromStorage = this.setRealm(realm);
      if (realmFromStorage) {
        this.keycloak.keycloakEvents$.subscribe({
          next: (event) => {
            if (event.type == KeycloakEventType.OnTokenExpired) {
              this.keycloak.updateToken(20)
                .catch(err => console.error('Token update failed', err));
            }
          }
        });

        await this.keycloak.init(keycloakSetup(
          this.keyCloakConfig.issuer,
          realmFromStorage,
          this.keyCloakConfig.clientId,
        ));

        await this.keycloak.login({
          redirectUri: window.location.origin + LipoRouteEnum.HOME,
          loginHint: mail,
        });
      }
    } catch (error) {
      console.error('Error during setRealmAndLogin: ', error);
    }
  }

  private setRealm(realm: string): string | null {
    localStorage.setItem(this.lSRealmProperty, realm);
    return this.getRealm();
  }

  private getRealm(): string | null {
    return localStorage.getItem(this.lSRealmProperty);
  }

  async clearRealm() {
    localStorage.removeItem(this.lSRealmProperty);
  }

  async clearRealmAndLogout() {
    await this.clearRealm()
    await this.keycloak.logout();
  }
}
