import {CustomerOverviewModel} from "../../../customer/components/models/customer-overview.model";
import {SystemOverviewModel} from "../../../system/services/models/system-overview.model";
import {AppLicenseOverviewModel} from "../../../licence/components/models/app-license-overview.model";

export class PartnerOverviewModel {
  constructor(
    public id: number | null,
    public name: string,
    public phone: string,
    public logoUrl: string | null,
    public customers: CustomerOverviewModel[] = [],
    public systems: SystemOverviewModel[] = [],
    public licenses: AppLicenseOverviewModel[] = [],
  ) { }
}
