<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div mat-dialog-title class="dialog-header">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>

    <div class="dialog-title">{{ "itInstructions.title" | translate | uppercase }}</div>
  </div>

  <mat-dialog-content class="dialog-content">
    <p>{{ "itInstructions.text" | translate }}</p>

    <table class="instructions-table">
      <tbody>
      <tr>
        <th>{{ "itInstructions.ipWhiteList" | translate }}</th>
        <td></td>
        <td>
          <div class="ip-list">
            @for (ip of ipWhitelist; track ip) {
              <div>{{ ip }}</div>
            }
          </div>
        </td>
      </tr>
        @if (hasTenants) {
          <tr>
            <th>{{ "tenants" | translate }}</th>
            <th>{{ "itInstructions.internalPort" | translate }}</th>
            <th>{{ "itInstructions.externalPort" | translate }}</th>
          </tr>
        }
        @for (tenant of tenants; track tenant) {
          <tr>
            <td>{{ tenant.name }}</td>
            <td>{{ tenant.internalPort }}</td>
            <td>{{ tenant.externalPort }}</td>
          </tr>
        }
        @if (!hasTenants) {
          <tr>
            <th>{{ "itInstructions.internalPort" | translate }}</th>
            <td></td>
            <td>{{ internalPort }}</td>
          </tr>
          <tr>
            <th>{{ "itInstructions.externalPort" | translate }}</th>
            <td></td>
            <td >{{ externalPort }}</td>
          </tr>
        }
      </tbody>
    </table>

  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions">
    <button mat-flat-button (click)="copyToClipboard()">
      {{ "button.copy" | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
