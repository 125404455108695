import {Component, OnInit} from '@angular/core';
import {PartnerDataService} from "../../services/partner-data.service";
import {PartnerServiceMapper} from "../../mappers/partner-service.mapper";
import {PartnerCardMapper} from "../../mappers/partner-card.mapper";
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {Router} from "@angular/router";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {TranslateModule} from "@ngx-translate/core";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {ProgressService} from "../../../shared/services/progress.service";

@Component({
  selector: 'du-partner',
  standalone: true,
  imports: [
    LipoOverviewComponent,
    TranslateModule
  ],
  templateUrl: './partner-overview.component.html',
  styleUrl: './partner-overview.component.scss'
})
export class PartnerOverviewComponent implements OnInit {
  partnerOverviewModel?: LipoOverviewModel;

  constructor(
    private _partnerService: PartnerDataService,
    protected router: Router,
    private _progressService: ProgressService,
  ) {
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this._partnerService.getPartners().subscribe({
      next: partners => {
        let partnersOverview = partners.map(PartnerServiceMapper.toPartnerOverviewMapper);
        let partnerCards = partnersOverview.map(PartnerCardMapper.toLipoCardModel);

        this.partnerOverviewModel = {
          title: 'partners',
          buttons: LipoButton.build({
            text: "button.partner.add",
            icon: "add",
            onClick: async () => await this.router.navigate([LipoRouteEnum.PARTNER, 'new'])
          }),
          cards: partnerCards
        }
      },
      complete: () => this._progressService.stopLoading()
    });
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
