import {Component, inject, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {v4 as uuidv4} from "uuid";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {ProductDataService} from "../../services/product-data.service";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {ProductDetailModel} from "../models/product-detail.model";
import {ProductServiceMapper} from "../../mappers/product-service.mapper";
import {Observable, of} from "rxjs";
import {
  LipoFormControlModel,
  LipoFormTextArea,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {ProductDetailMapper} from "../../mappers/product-detail.mapper";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {LipoTab} from "../../../shared/components/models/lipoTab";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {DatePipe, LowerCasePipe} from "@angular/common";
import {ProductPriceDetailModel} from "../../../productPrice/components/models/product-price-detail.model";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {MatDialog} from "@angular/material/dialog";
import {LicenseWizardDialogComponent} from "../../../licence/components/wizard/license-wizard-dialog.component";
import {LicenseWizardModel} from "../../../licence/components/models/license-wizard.model";
import {RegexPatterns} from "../../../utils/regex-patterns";
import {ProgressService} from "../../../shared/services/progress.service";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {KeycloakService} from "keycloak-angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {PrependTextPipe} from "../../../shared/pipes/prepend-text.pipe";

@Component({
  selector: 'du-prodcut-detail',
  standalone: true,
  imports: [
    LipoDetailComponent,
    TranslateModule
  ],
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.scss'
})
export class ProductDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  productId: number | null = null
  partnerId: number | null = null
  isSaving: boolean = false
  dialog = inject(MatDialog);

  isUserAllowedToEdit: boolean = false
  productPage: string = ''

  private readonly subscriptionPrependTextPipe: PrependTextPipe;
  private readonly _lowerCasePipe = new LowerCasePipe();
  protected readonly LipoRouteEnum = LipoRouteEnum;
  private readonly _priceTableUuid: string = uuidv4();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _productService: ProductDataService,
    private _snackBar: SnackbarService,
    private _datePipe: DatePipe,
    private _progressService: ProgressService,
    private keycloakService: KeycloakService,
    private translationService: TranslateService,
  ) {
    _progressService.startLoading()
    let prependText = new PrependTextPipe()
    prependText.setPrepend('subscription.')
    this.subscriptionPrependTextPipe = prependText;
  }

  ngOnInit(): void {
    this.checkUserEmail().then(
      (isAllowed) => {
        this.isUserAllowedToEdit = isAllowed;
        this.initializeDataToEdit();
      }
    );
  }

  initializeDataToEdit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        let routesDataModel = data as LipoRoutesDataModel;
        if (routesDataModel.isCreateItem) {
          this.createEmptyProduct();
        } else {
          this._activatedRoute.paramMap.subscribe({
            next: paramMap => {
              let idParam = paramMap.get('productId');
              if (!idParam) return;

              let id = +idParam;

              this.productId = id
              this.loadProduct(+id);
            }
          })
        }
      }
    })
  }

  loadProduct(id: number): void {
    this._productService.getProduct(id).subscribe({
      next: value => {
        let productModel = ProductServiceMapper.toProductDetailModel(value)

        this.partnerId = productModel.partnerId ?? null;
        this.createDetail(productModel);
      }
    })
  }

  createEmptyProduct(): void {
    if (this.isUserAllowedToEdit) {
      let emptyPartnerModel = new ProductDetailModel();
      this.createSaveDetail(emptyPartnerModel);
    } else {
      this._router.navigate([LipoRouteEnum.PRODUCT]).then(() => {
          this._snackBar.Warning(this.translationService.instant("snackbar.notfound"))
        }
      );
    }
  }

  createSaveDetail(productModel: ProductDetailModel): void {
    this.getFormFields(productModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel([new LipoFormGroupModel({controls: baseForms, columns: 2})])
        this.detailModel = ProductDetailMapper.toLipoDetailModel(productModel, lipoFormModel)
      },
      complete: () => this._progressService.stopLoading()
    })
  }

  createDetail(productModel: ProductDetailModel): void {
    this.productPage = productModel.url ?? ''

    if (this.isUserAllowedToEdit) {
      this.getFormFields(productModel).subscribe({
        next: baseForms => {
          let lipoFormModel = new LipoFormModel([new LipoFormGroupModel({
            controls: baseForms,
          })])
          this.detailModel = ProductDetailMapper.toLipoDetailModel(productModel, lipoFormModel, this.getTabs(productModel))
        },
        complete: () => this._progressService.stopLoading()
      })
    } else {
      this.getFieldsReadOnly(productModel).subscribe({
        next: baseForms => {
          let lipoFormModel = new LipoFormModel([new LipoFormGroupModel({
            controls: baseForms,
            columns: 1
          })])
          this.detailModel = ProductDetailMapper.toLipoDetailModel(productModel, lipoFormModel, this.getTabs(productModel))
        },
        complete: () => this._progressService.stopLoading()
      })
    }
  }

  getFormFields(model: ProductDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.name, [Validators.required, Validators.pattern(RegexPatterns.Name)]),
        key: 'name',
        label: 'name',
      }),
      new LipoFormTextArea({
        value: new FormControl(model.description),
        key: 'description',
        label: 'description',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.image),
        key: 'image',
        label: 'logo_url',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.url),
        key: 'url',
        label: 'product_url',
      }),
    ];
    return of(fields);
  }

  getFieldsReadOnly(model: ProductDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.name, [Validators.required, Validators.pattern(RegexPatterns.Name)]),
        key: 'name',
        label: 'name',
      }),
      new LipoFormTextArea({
        value: new FormControl(model.description),
        key: 'description',
        label: 'description',
      }),
    ];
    return of(fields);
  }

  getTabs(model: ProductDetailModel): LipoTab[] {
    return LipoTab.build(
      {
        title: 'prices',
        components: [
          {
            uuid: this._priceTableUuid,
            component: this.getCustomerTable(model.productPrice)
          }
        ]
      }
    );
  }

  getCustomerTable(productPrices: ProductPriceDetailModel[]) {
    let table: LipoTableDisplayedColumns[]
    let buttons: LipoButton[] = [];

    if (this.isUserAllowedToEdit) {
      table = LipoTableDisplayedColumns.build(
        {HeaderCellName: 'id', PropertyName: 'id'},
        {HeaderCellName: 'price', PropertyName: 'price'},
        {HeaderCellName: 'currency', PropertyName: 'currency'},
        {HeaderCellName: 'valid.from', PropertyName: 'validFrom', BodyPipes: [this._datePipe]},
        {HeaderCellName: 'valid.to', PropertyName: 'validTo', BodyPipes: [this._datePipe]},
        { HeaderCellName: 'subscription.type', PropertyName: 'type',
          BodyPipes: [this._lowerCasePipe, this.subscriptionPrependTextPipe]}
      )
      buttons = LipoButton.build({
        text: "button.product.price.add",
        icon: "add",
        onClick: async () => await this._router.navigate([LipoRouteEnum.PRODUCT, this.productId, LipoRouteEnum.PRODUCT_PRICE, 'new'])
      })
    } else {
      table = LipoTableDisplayedColumns.build(
        {HeaderCellName: 'price', PropertyName: 'price'},
        {HeaderCellName: 'currency', PropertyName: 'currency'},
        {HeaderCellName: 'subscription.type', PropertyName: 'type', BodyPipes: [this.subscriptionPrependTextPipe, this._lowerCasePipe]},
      )
    }

    return new LipoTableModel<ProductPriceDetailModel>(
      new MatTableDataSource<ProductPriceDetailModel>(productPrices),
      table,
      this.isUserAllowedToEdit ? new SelectionModel<ProductPriceDetailModel>(true, []) : undefined,
      buttons,
      this.isUserAllowedToEdit ? (value: LipoModelInterface) => this.onCustomerRowClick(value) : undefined
    )
  }

  async onCustomerRowClick(value: LipoModelInterface) {
    let id = value.getId();

    if (id) {
      await this._router.navigate([LipoRouteEnum.PRODUCT, this.productId, LipoRouteEnum.PRODUCT_PRICE, id]);
    }
  }

  onDeleteClicked(id: number | null): void {
    if (id === null) return;

    this._productService.deleteProduct(id).subscribe({
      next: _ => {
        this._router.navigate([LipoRouteEnum.PRODUCT]).then(() => this._snackBar.Deleted());
      }
    })
  }

  onSaveClick(detailModel: LipoDetailModel, id: number | null): void {
    if (!detailModel.form?.formGroup) return;

    let productPriceTab = detailModel.findComponent<LipoTableModel<ProductPriceDetailModel>>(this._priceTableUuid)
    let serviceProductModel = LipoFormMapper.toProductServiceModel(detailModel.form?.formGroup, productPriceTab?.tableDataSource.data);

    this.isSaving = true
    if (id !== null && id > 0) {
      serviceProductModel.id = id;
      serviceProductModel.partnerId = this.partnerId!;
      this._productService.updateProduct(serviceProductModel).subscribe({
        next: partner => {
          this.createDetail(ProductServiceMapper.toProductDetailModel(partner));
          this._snackBar.Saved();
        },
        error: () => this.isSaving = false,
        complete: () => this.isSaving = false
      });
    } else {
      this._productService.createProduct(serviceProductModel).subscribe(
        {
          next: product => {
            this._router.navigate([LipoRouteEnum.PRODUCT, product.id]).then(() => this._snackBar.Saved());
          },
          error: () => this.isSaving = false,
          complete: () => this.isSaving = false
        });
    }
  }

  onLicenseWizardClicked(): void {
    this.dialog.open(LicenseWizardDialogComponent, {
      data: new LicenseWizardModel({productId: this.productId}),
    }).afterClosed().subscribe({
      next: result => {
        if(result) {
          this.loadProduct(this.productId!);
        }
      }
    });
  }

  async checkUserEmail(): Promise<boolean> {
    try {
      const profile = await this.keycloakService.loadUserProfile();
      return profile.email?.endsWith('@dataunit.ch') ?? false;
    } catch {
      return false;
    }
  }

  getExtraHeaderButton(): LipoButton[] {
    if (!this.isUserAllowedToEdit) {
      return LipoButton.build(
        {
          text: "button.more_information",
          icon: "open_in_new",
          onClick: async () => {
            window.open(this.productPage, '_blank');
          }
        }
      );
    }
    return []
  }
}
