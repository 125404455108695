import {Component, OnInit} from '@angular/core';
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {UserDetailModel, UserSaveDetailModel} from "../models/user-detail.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {UserDetailMapper} from "../../mappers/user-detail.mapper";
import {Observable, of} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {LipoFormControlModel, LipoFormTextbox} from "../../../shared/components/models/lipo-form-control.model";
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {UserSaveDetailMapper} from "../../mappers/user-save-detail.mapper";
import {CustomerDataService} from "../../../customer/services/customer-data.service";
import {UserServiceMapper} from "../../mappers/user-service.mapper";
import {ProgressService} from "../../../shared/services/progress.service";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";

@Component({
  selector: 'du-user-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './user-detail.component.html',
  styleUrl: './user-detail.component.scss'
})
export class UserDetailComponent implements OnInit{
  detailModel?: LipoDetailModel;
  customerId: number | null = null;
  userId: number | null = null;

  protected readonly LipoRouteEnum = LipoRouteEnum;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackbarService,
    private _customerService: CustomerDataService,
    private _progressService: ProgressService,
  ) {
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        this.handleRouteData(data);
      }
    });
  }

  private handleRouteData(data: Data): void {
    const routesDataModel = data as LipoRoutesDataModel;
    this.handleParams();
    this.handleUserData(routesDataModel);
  }

  private handleParams(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.customerId = this.idToNumber(params.get('customerId'));
      this.userId = this.idToNumber(params.get('userId'));
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  private handleUserData(routesDataModel: LipoRoutesDataModel): void {
    if (routesDataModel.isCreateItem) {
      this.createEmptyUser();
    } else {
      if (this.userId !== null && this.customerId !== null) {
        this._customerService.getCustomerUser(this.customerId, this.userId).subscribe({
          next: value => {
            let userDetailModel = UserServiceMapper.toUserDetailModel(value)
            this.createDetail(userDetailModel);
          }
        })
      }
    }
  }

  createEmptyUser(): void {
    let emptyUserSaveModel = new UserSaveDetailModel();
    this.createSaveDetail(emptyUserSaveModel);
  }

  createSaveDetail(userModel: UserSaveDetailModel): void {
    this.getFormSaveFields(userModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel([new LipoFormGroupModel({controls: baseForms})])
        this.detailModel = UserSaveDetailMapper.toLipoDetailSaveModel(lipoFormModel)
      },
      complete: () => this._progressService.stopLoading()
    });
  }

  createDetail(userModel: UserDetailModel): void {
    this.getFormFields(userModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel([new LipoFormGroupModel({controls: baseForms})])
        this.detailModel = UserDetailMapper.toLipoDetailModel(userModel, lipoFormModel)
      },
      complete: () => this._progressService.stopLoading()
    });
  }

  getFormSaveFields(model: UserSaveDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.email, [Validators.required, Validators.email]),
        key: 'email',
        label: 'mail'
      }),
      new LipoFormTextbox({
        value: new FormControl(model.firstName),
        key: 'firstName',
        label: 'firstName'
      }),
      new LipoFormTextbox({
        value: new FormControl(model.lastName),
        key: 'lastName',
        label: 'lastName'
      }),
      new LipoFormTextbox({
        value: new FormControl(model.sapUser),
        key: 'sapUser',
        label: 'sapUser'
      })
    ]
    return of(fields)
  }

  getFormFields(model: UserDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.email, [Validators.required, Validators.email]),
        key: 'email',
        label: 'mail'
      }),
      new LipoFormTextbox({
        value: new FormControl(model.firstName),
        key: 'firstName',
        label: 'firstName'
      }),
      new LipoFormTextbox({
        value: new FormControl(model.lastName),
        key: 'lastName',
        label: 'lastName'
      }),
      new LipoFormTextbox({
        value: new FormControl(model.sapUser),
        key: 'sapUser',
        label: 'sapUser'
      }),
      new LipoFormTextbox({
        value: new FormControl(model.keyCloakUserId),
        key: 'keyCloakUserId',
        label: 'keycloak.userId'
      }),
    ]

    return of(fields)
  }

  onSaveClick(detailModel: LipoDetailModel): void {
    if (!detailModel.form?.formGroup || !this.customerId) return;
    let id = this.userId

    if (id && id > 0) {
      let userModel = LipoFormMapper.toUserServiceModel(detailModel.form?.formGroup)
      userModel.id = id;

      this._customerService.updateCustomerUser(this.customerId, userModel).subscribe({
        next: user => {
          this.createDetail(UserServiceMapper.toUserDetailModel(user));
          this._snackBar.Saved();
        }
      })
    } else {
      let userModel = LipoFormMapper.toUserSaveServiceModel(detailModel.form?.formGroup)
      this._customerService.createCustomerUser(this.customerId, userModel).subscribe({
        next: user => {
          this._router.navigate([LipoRouteEnum.CUSTOMER, this.customerId, LipoRouteEnum.USER, user.id]).then(() => this._snackBar.Saved());
        }
      })
    }
  }
}
