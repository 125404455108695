import {KeycloakRoleEnum} from "./enums/keycloak-role.enum";

export const routeRoleConfig = {
  partner: [KeycloakRoleEnum.ADMIN],
  customer: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER],
  system: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER],
  license: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER],
  product: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER],
  tenant: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER],
  userMapping: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER],
  token: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER],
  appSetting: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER],
  productPrice: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER],
  userRole: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER],
};
