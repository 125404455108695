import {UserServiceSaveModel} from "../services/models/user-service.model";
import {UserSaveDetailModel} from "../components/models/user-detail.model";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";

export class UserSaveDetailMapper {
  public static toUserSaveServiceModel(userDetailModel: UserSaveDetailModel): UserServiceSaveModel {
    return {
      firstName: userDetailModel.firstName,
      lastName: userDetailModel.lastName,
      email: userDetailModel.email,
      sapUser: userDetailModel.sapUser,
      keyCloakUserId: userDetailModel.keyCloakUserId,
    } as UserServiceSaveModel
  }

  public static toLipoDetailSaveModel(formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      'user',
      null,
      null,
      formModel,
      tabs,
    )
  }
}
