import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable, of} from "rxjs";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {TenantServiceModel} from "./models/tenant-service.model";
import {SystemServiceModel} from "../../system/services/models/system-service.model";

@Injectable({
  providedIn: 'root'
})
export class TenantDataService {
  private readonly endpoint: string;
  private readonly systemEndpoint: string;
  private readonly api: string;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {
    this.endpoint = this.config.configuration.api.endpoints.tenant;
    this.systemEndpoint = this.config.configuration.api.endpoints.system;
    this.api = this.config.configuration.api.backendUrl;
  }

  createTenant(tenant: TenantServiceModel, systemId: number): Observable<TenantServiceModel> {
    return this.http.post<SystemServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.endpoint}`, tenant).pipe(
      map((systemServiceModel: SystemServiceModel) => this.getTenantByName(systemServiceModel, tenant.sapCompanyDB))
    );
  }

  deleteTenant(tenantId: number, systemId: number): Observable<SystemServiceModel> {
    return this.http.delete<SystemServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.endpoint}/${tenantId}`);
  }

  updateTenant(tenant: TenantServiceModel, systemId: number): Observable<TenantServiceModel> {
    return this.http.put<SystemServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.endpoint}/${tenant.id}`, tenant).pipe(
      map((systemServiceModel: SystemServiceModel) => this.getTenantById(systemServiceModel, tenant.id))
    );
  }

  pingTenant(systemId: number, tenantId: number): Observable<boolean> {
    const url = `${this.api}/${this.systemEndpoint}/ping?systemId=${systemId}&tenantId=${tenantId}`;
    return this.http.get<any>(url).pipe(
      map((response) => !!response?.value),
      catchError(() => of(false))
    );
  }

  private getTenantById(systemServiceModel: SystemServiceModel, tenantId: number | null): TenantServiceModel {
    const updatedTenant = systemServiceModel.tenants.find(tenant => tenant.id === tenantId);
    return updatedTenant || {} as TenantServiceModel;
  }

  private getTenantByName(systemServiceModel: SystemServiceModel, tenantName: string | null): TenantServiceModel {
    const updatedTenant = systemServiceModel.tenants.find(tenant => tenant.sapCompanyDB === tenantName);
    return updatedTenant || {} as TenantServiceModel;
  }
}
