import {Component, inject, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {ActivatedRoute, Router} from "@angular/router";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {SystemDataService} from "../../services/system-data.service";
import {SystemServiceMapper} from "../../mappers/system-service.mapper";
import {forkJoin, of} from "rxjs";
import {LipoFormControlModel} from "../../../shared/components/models/lipo-form-control.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {SystemDetailModel, SystemSaveDetailModel} from "../models/system-detail.model";
import {LipoTab} from "../../../shared/components/models/lipoTab";
import {LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {SystemDetailMapper} from "../../mappers/system-detail.mapper";
import {SystemSaveDetailMapper} from "../../mappers/system-save-detail.mapper";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {v4 as uuidv4} from "uuid";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TenantDetailModel} from "../../../tenant/components/models/tenant-detail.model";
import {CustomerDataService} from "../../../customer/services/customer-data.service";
import {CustomerServiceModel} from "../../../customer/services/models/customer-service.model";
import {KeycloakRoleEnum} from "../../../shared/enums/keycloak-role.enum";
import {KeycloakService} from "keycloak-angular";
import {ConfigurationService} from "../../../shared/services/configuration.service";
import {MatDialog} from "@angular/material/dialog";
import {LicenseWizardDialogComponent} from "../../../licence/components/wizard/license-wizard-dialog.component";
import {LicenseWizardModel} from "../../../licence/components/models/license-wizard.model";
import {getSystemDetailFormFields, getSystemSaveDetailFormFields} from "./system-detail.form-fields";
import {getAdminTenantDetailSaveFormFields} from "../../../tenant/components/tenant-detail/tenant-detail.form-fields";
import {getLicensesTable, getSystemTenantsTable} from "./system-detail.table";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {ProgressService} from "../../../shared/services/progress.service";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {
  TenantItInstructionsDialogComponent
} from "../../../tenant/components/tenant-it-instructions-dialog/tenant-it-instructions-dialog.component";
import {TenantItInstructionsService} from "../../../tenant/services/tenant-it-instructions.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'du-system-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './system-detail.component.html',
  styleUrl: './system-detail.component.scss'
})
export class SystemDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  systemId: number | null = null;
  isSaving: boolean = false
  customers?: CustomerServiceModel[]
  dialog = inject(MatDialog);

  protected readonly LipoRouteEnum = LipoRouteEnum;

  private readonly _tenantTableUuid = uuidv4();
  private readonly _appLicensesTableUuid = uuidv4();
  downloadUrl: string;

  constructor(
    private configService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _systemService: SystemDataService,
    private _snackBar: SnackbarService,
    private _customerService: CustomerDataService,
    private _progressService: ProgressService,
    private _datePipe: DatePipe,
    private readonly _keycloakService: KeycloakService,
    private tenantConfigService: TenantItInstructionsService,
  ) {
    _progressService.startLoading()
    this.downloadUrl = this.configService.configuration.downloadUrl;
  }

  ngOnInit(): void {
    this._customerService.getCustomers().subscribe({
      next: customer => {
        this.customers = customer.content;
        this._activatedRoute.queryParams.subscribe(params => {
          const customerId = this.idToNumber(params['customerId']);
          this.handleSystem(customerId);
        });
      }
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  handleSystem(customerId: number | null): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        let routesDataModel = data as LipoRoutesDataModel;
        if (routesDataModel.isCreateItem) {
          this.createEmptySystem(customerId);
        } else {
          this._activatedRoute.paramMap.subscribe({
            next: paramMap => {
              let idParam = paramMap.get('systemId');
              if (!idParam) return;

              let id = +idParam;
              this.systemId = id;
              this.loadSystem(id);
            }
          });
        }
      }
    });
  }

  loadSystem(id: number): void {
    this._systemService.getSystem(id).subscribe({
      next: value => {
        let systemDetailModel = SystemServiceMapper.toSystemDetailModel(value)
        this.createDetail(systemDetailModel);
      }
    });
  }

  createEmptySystem(customerId: number | null): void {
    let emptySystemModel = new SystemSaveDetailModel();
    emptySystemModel.customer = customerId;

    this.createSaveDetail(emptySystemModel);
  }

  createSaveDetail(systemDetailModel: SystemSaveDetailModel): void {
    let adminForm$ = this._keycloakService.isUserInRole(KeycloakRoleEnum.ADMIN) ? getAdminTenantDetailSaveFormFields(systemDetailModel) : of([]);

    forkJoin({
      adminForm: adminForm$,
      baseForm: getSystemSaveDetailFormFields(systemDetailModel, this.customers),
    }).subscribe(({adminForm, baseForm}) => {
      let baseForms = [...baseForm, ...adminForm] as LipoFormControlModel[];
      let lipoFormModel = new LipoFormModel([new LipoFormGroupModel({controls: baseForms})]);
      this.detailModel = SystemSaveDetailMapper.toLipoDetailSaveModel(systemDetailModel, lipoFormModel);
      this._progressService.stopLoading()
    });
  }

  createDetail(systemDetailModel: SystemDetailModel): void {
    const isAdmin =   this._keycloakService.isUserInRole(KeycloakRoleEnum.ADMIN);

    getSystemDetailFormFields(systemDetailModel, isAdmin, this.customers).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel(baseForms)
        let tabs = this.getTabs(systemDetailModel)
        this.detailModel = SystemDetailMapper.toLipoDetailModel(systemDetailModel, lipoFormModel, tabs);
      },
      complete: () => this._progressService.stopLoading()
    });
  }

  getTabs(model: SystemDetailModel): LipoTab[] {
    const activeTenants = model.tenants.filter(tenant => tenant.active);

    return LipoTab.build(
      {
        title: 'tenants',
        components: [
          {
            uuid: this._tenantTableUuid,
            component: getSystemTenantsTable(model.tenants, this.getTenantTableButtons(), this.onTenantTableRowClick.bind(this)),
          }
        ]
      },
      {
        title: 'licenses',
        components: [
          {
            uuid: this._appLicensesTableUuid,
            component: getLicensesTable(activeTenants, this._datePipe, this.getTenantTableButtons()),
          }
        ]
      },
    );
  }

  getTenantTableButtons(): LipoButton[] {
    return LipoButton.build({
      text: "button.tenant.add",
      icon: "add",
      onClick: () => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, 'new']),
    });
  }

  async onTenantTableRowClick(value: LipoModelInterface) {
    let id = value.getId();

    let tenantList = this.detailModel?.findComponent<LipoTableModel<TenantDetailModel>>(this._tenantTableUuid)
    let tenant = tenantList?.tableDataSource.data.find(data => data.getId() === id);

    if (tenant) {
      await this._router.navigate([
        LipoRouteEnum.SYSTEM,
        this.systemId,
        LipoRouteEnum.TENANT,
        tenant.id
      ]);
    }
  }

  onSaveClick(detailModel: LipoDetailModel, id: number | null): void {
    if (!detailModel.form?.formGroup) return;

    let tenantTab = detailModel.findComponent<LipoTableModel<TenantDetailModel>>(this._tenantTableUuid);

    this.isSaving = true
    if (id !== null && id > 0) {
      let systemServiceModel = LipoFormMapper.toSystemServiceModel(detailModel.form?.formGroup, tenantTab?.tableDataSource.data);
      systemServiceModel.id = id;

      this._systemService.updateSystem(systemServiceModel).subscribe({
        next: system => {
          this.createDetail(SystemServiceMapper.toSystemDetailModel(system));
          this._snackBar.Saved();
        },
        error: () => this.isSaving = false,
        complete: () => this.isSaving = false
      });
    } else {
      let systemSaveServiceModel = LipoFormMapper.toSystemSaveServiceModel(detailModel.form?.formGroup);

      this._systemService.createSystem(systemSaveServiceModel).subscribe({
        next: system => {
          this._router.navigate([LipoRouteEnum.SYSTEM, system.id]).then(() => this._snackBar.Saved());
        },
        error: () => this.isSaving = false,
        complete: () => this.isSaving = false
      })
    }
  }

  onDeleteClicked(id: number | null): void {
    if (id === null) return;

    this._systemService.deleteSystem(id).subscribe({
      next: (successfully) => {
        if (successfully) {
          this._router.navigate([LipoRouteEnum.SYSTEM]).then(() => this._snackBar.Deleted());
        }
      }
    });
  }

  onDownloadClick(): void {
    const link = document.createElement('a');
    link.href = this.downloadUrl;
    link.download = 'DUAgentSetup.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onLicenseWizardClicked(): void {
    this.dialog.open(LicenseWizardDialogComponent, {
      data: new LicenseWizardModel({systemId: this.systemId}),
    }).afterClosed().subscribe({
      next: result => {
        if(result) {
          this.loadSystem(this.systemId!);
        }
      }
    });
  }

  onITInstructionsClicked(): void {
    const tenantList = this.detailModel?.findComponent<LipoTableModel<TenantDetailModel>>(this._tenantTableUuid);
    const tenantsWithPort = tenantList?.tableDataSource.data.filter(tenant => tenant.port && tenant.active);

    const ipWhitelist = this.tenantConfigService.getTenantITInstructionsIpWhitelist();

    this.dialog.open(TenantItInstructionsDialogComponent, {
      data: {
        ipWhitelist,
        internalPort: null,
        externalPort: null,
        tenants: tenantsWithPort?.map(tenant => ({
          name: tenant.serviceName,
          internalPort: tenant.port,
          externalPort: tenant.port,
        })),
        showTenantNames: true,
      },
    });
  }

  getMenuButtons(): LipoButton[] {
    return LipoButton.build(
      {
        text: "button.download",
        icon: "download",
        onClick: async () => this.onDownloadClick(),
      },
      {
        text: "button.license.activate",
        icon: "license",
        onClick: async () => this.onLicenseWizardClicked(),
      },
      {
        text: "button.it.instructions",
        icon: "info",
        onClick: async () => this.onITInstructionsClicked(),
      }
    );
  }
}
