import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {CustomerDetailModel} from "../components/models/customer-detail.model";
import {CustomerServiceModel} from "../services/models/customer-service.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";
import {SystemDetailMapper} from "../../system/mappers/system-detail.mapper";
import {PartnerDetailModel} from "../../partner/components/models/partner-detail.model";
import {UserDetailMapper} from "../../user/mappers/user-detail.mapper";

export class CustomerDetailMapper {
  public static toLipoDetailModel(customer: CustomerDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      customer.name.length > 0 ? customer.name : 'customer',
      customer.id ? `#${customer.id}` : null,
      customer.logoUrl,
      formModel,
      tabs
    )
  }

  public static toCustomerServiceModel(customer: CustomerDetailModel): CustomerServiceModel {
    let partnerDetailModel = customer.partner as PartnerDetailModel;

    return {
      name: customer.name,
      phone: customer.phone,
      mail: customer.mail,
      logo: customer.logoUrl,
      partner: partnerDetailModel ? partnerDetailModel.id : null,
      addresses: customer.addresses,
      systems: customer.systems.map(SystemDetailMapper.toSystemServiceModel),
      users: customer.users.map(UserDetailMapper.toUserServiceModel),
      id: customer.id,
      createUsersInKeyCloak: customer.createUsersInKeyCloak,
      sendMailToCustomer: customer.sendMailToCustomer,
      sendMailToPartner: customer.sendEmailToPartner,
    }
  }
}
