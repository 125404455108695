import {AppLicenseServiceModel} from "../services/models/app-license-view-service.model";
import {AppLicenseDetailModel} from "../components/models/app-license-detail.model";

export class AppLicenseDetailMapper {
  public static toAppLicenseServiceModel(appLicenseDetailModel: AppLicenseDetailModel): AppLicenseServiceModel {
    return {
      purchaseDate: appLicenseDetailModel.purchaseDate.format('YYYY-MM-DD'),
      expiryDate: appLicenseDetailModel.expiryDate.format('YYYY-MM-DD'),
      customerId: appLicenseDetailModel.customerId ?? 0,
      productId: appLicenseDetailModel.productId ?? 0,
      productPriceId: appLicenseDetailModel.productPriceId ?? 0,
      tenantId: appLicenseDetailModel.tenantId ?? 0,
      isActive: appLicenseDetailModel.isActive ?? false,
      id: appLicenseDetailModel.id ?? 0
    }
  }
}
