import {LipoFormControlModel} from "./lipo-form-control.model";
import {LipoButton} from "./lipo-button";

export class LipoFormGroupModel {
  key: string;
  label: string | null;
  order: number;
  controls: LipoFormControlModel[];
  buttons: LipoButton[];
  columns: number;
  style: string;

  constructor(options: {
    key?: string;
    label?: string;
    order?: number;
    controls?: LipoFormControlModel[];
    buttons?: LipoButton[];
    columns?: number;
    style?: string;
  } = {}) {
    this.key = options.key ?? 'default';
    this.label = options.label ?? null;
    this.order = options.order ?? 1;
    this.controls = options.controls ?? [];
    this.buttons = options.buttons ?? [];
    this.columns = options.columns ?? 2;
    this.style = options.style ?? '';
  }
}
