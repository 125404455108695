import {UserServiceModel} from "../services/models/user-service.model";
import {UserDetailModel} from "../components/models/user-detail.model";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";

export class UserDetailMapper {
  public static toUserServiceModel(userDetailModel: UserDetailModel): UserServiceModel {
    return {
      firstName: userDetailModel.firstName,
      lastName: userDetailModel.lastName,
      email: userDetailModel.email,
      sapUser: userDetailModel.sapUser,
      keyCloakUserId: userDetailModel.keyCloakUserId,
      id: userDetailModel.id
    } as UserServiceModel
  }

  public static toLipoDetailModel(user: UserDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      user.id ? user.email : 'user',
      user.id ? `#${user.id}` : null,
      null,
      formModel,
      tabs,
      user.id
    )
  }
}
