@if (formModel) {
  <div class="form-container" [formGroup]="formModel.formGroup">
    @for (group of formModel.baseForms; track $index) {
      <div class="form-group-container" [style.--group-container-columns]="formModel.columns">
        @if (group.label) {
          <div class="form-group-header">
            <h2 class="mat-title-medium">{{ group.label | translate }}</h2>
          </div>
        }
        <div class="form-group-body" [formGroupName]="group.key" [style]="group.style"
             [style.--group-body-columns]="group.columns">
          @for (control of group.controls; track $index) {
            <div>
              <div>
                <h5 class="mat-title-medium" style="font-weight: 450; margin-bottom: 0">{{ control.label | translate }}</h5>
              </div>
              <div>
                <p class="mat-body-medium">
                  {{ control.value.getRawValue() }}
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
