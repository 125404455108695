import {Injectable, signal, WritableSignal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private readonly _isLoading: WritableSignal<boolean> = signal(false);

  constructor() {
  }

  startLoading(): void {
    this._isLoading.set(true);
  }

  stopLoading(): void {
    this._isLoading.set(false);
  }

  get isLoading() {
    return this._isLoading.asReadonly();
  }
}
