import {Type} from '@angular/core';
import {LipoTableModel} from "../shared/components/models/lipo-table.model";
import {LipoModelInterface} from "../shared/interfaces/lipo-model.interface";

/**
 * This function verifies if a component is of a specific type 'Type<any>'.
 *
 * @param {Type<any> | LipoTableModel<any> | undefined} component - The Angular component to check.
 * @returns {boolean} Returns a boolean value indicating whether the component is of type 'Type<any>'.
 */
export function isTypeAny(component: Type<any> | LipoTableModel<any> | undefined): component is Type<any> {
  return component !== undefined && (component as Type<any>).prototype !== undefined;
}

/**
 * This function checks if a given variable is an instance of the 'LipoTableModel'.
 *
 * @template T
 * @param {any} variable - The variable to validate.
 * @returns {boolean} Returns a boolean value indicating whether the variable is an instance of the 'LipoTableModel'.
 */
export function isLipoTableModel<T extends LipoModelInterface>(variable: any): variable is LipoTableModel<T> {
  return variable instanceof LipoTableModel;
}
