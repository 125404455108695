import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'prependTextPipe',
  standalone: true
})
export class PrependTextPipe implements PipeTransform {
  private defaultPrepend: string = '';

  transform(value: string, prepend?: string): string {
    return `${prepend ?? this.defaultPrepend}${value}`;
  }

  setPrepend(value: string) {
    this.defaultPrepend = value;
  }
}
