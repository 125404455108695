import {TenantServiceModel} from "../services/models/tenant-service.model";
import {TenantDetailModel} from "../components/models/tenant-detail.model";
import {TenantAppSettingsServiceMapper} from "./tenant-app-settings-service.mapper";
import {TenantTokenServiceMapper} from "./tenant-token-service.mapper";
import {TenantUserMappingServiceMapper} from "./tenant-user-mapping-service.mapper";
import {AppLicenseServiceMapper} from "../../licence/mappers/app-license-service.mapper";

export class TenantServiceMapper {
  public static toTenantDetailModel(tenantServiceModel: TenantServiceModel): TenantDetailModel {
    return new TenantDetailModel(
      tenantServiceModel.active,
      tenantServiceModel.active ? "active" : "idle",
      tenantServiceModel.artifactDate,
      tenantServiceModel.checkInbound,
      tenantServiceModel.directory,
      tenantServiceModel.gitlabJobName,
      tenantServiceModel.gitlabProjectId,
      tenantServiceModel.gitlabRefName,
      tenantServiceModel.port === 0 ? null : tenantServiceModel.port,
      tenantServiceModel.externalPort === 0 ? null : tenantServiceModel.externalPort,
      tenantServiceModel.sapCompanyDB,
      tenantServiceModel.sapPassword,
      tenantServiceModel.sapUser,
      tenantServiceModel.serviceName,
      tenantServiceModel.testSystem,
      tenantServiceModel.suspended,
      tenantServiceModel.duifLicense ?? '',
      tenantServiceModel.installNo ?? '',
      tenantServiceModel.endpointUrl ?? '',
      tenantServiceModel.allowWithoutAuth,
      tenantServiceModel.proxyName,
      tenantServiceModel.appLicenses.map(AppLicenseServiceMapper.toAppLicenseDetailModel),
      tenantServiceModel.tokens.map(TenantTokenServiceMapper.toTenantTokenDetailModel),
      tenantServiceModel.appSettings.map(TenantAppSettingsServiceMapper.toTenantAppSettingsDetailModel),
      tenantServiceModel.tenantUserMappings.map(TenantUserMappingServiceMapper.toTenantUserMappingDetailModel),
      tenantServiceModel.id
    )
  }
}
