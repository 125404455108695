import {TenantAppSettingsServiceModel} from "../services/models/tenant-app-settings-service.model";
import {TenantAppSettingsDetailModel} from "../components/models/tenant-app-settings-detail.model";

export class TenantAppSettingsServiceMapper {
  public static toTenantAppSettingsDetailModel(
    tenantAppSettingsServiceModel: TenantAppSettingsServiceModel
  ): TenantAppSettingsDetailModel {
    return new TenantAppSettingsDetailModel(
      tenantAppSettingsServiceModel.appName,
      tenantAppSettingsServiceModel.description,
      tenantAppSettingsServiceModel.attributes,
      tenantAppSettingsServiceModel.id
    );
  }

}
