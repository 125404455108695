import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {TenantAppSettingsDetailModel} from "./tenant-app-settings-detail.model";
import {UserDetailModel} from "../../../user/components/models/user-detail.model";

export class TenantUserMappingDetailModel implements LipoModelInterface {
  constructor(
    public sapUserName: string = '',
    public sapUserCode: string = '',
    public user: UserDetailModel | null = null,
    public firstName: string = '',
    public lastName: string = '',
    public mail: string = '',
    public userId: number | null = null,
    public tenantId: number | null = null,
    public appSettings: TenantAppSettingsDetailModel[] | null = null,
    public id: number | null = null,
  ) {
  }

  getId(): string | number | null {
    return this.id;
  }
}
