import {LipoRouteEnum} from "./enums/lipo-route.enum";
import {routeRoleConfig} from "./routeRoleConfig";

export const navigationConfig = [
  {
    link: LipoRouteEnum.PARTNER,
    name: 'partners',
    icon: 'partner_exchange',
    fontSet: 'material-symbols-outlined',
    description: 'Partner Management',
    roles: routeRoleConfig.partner
  },
  {
    link: LipoRouteEnum.CUSTOMER,
    name: 'customers',
    icon: 'groups',
    fontSet: 'material-symbols-outlined',
    description: 'Customer Management',
    roles: routeRoleConfig.customer
  },
  {
    link: LipoRouteEnum.SYSTEM,
    name: 'systems',
    icon: 'dns',
    fontSet: 'material-symbols-outlined',
    description: 'System Management',
    roles: routeRoleConfig.system
  },
  {
    link: LipoRouteEnum.LICENCE,
    name: 'licenses',
    icon: 'license',
    fontSet: 'material-symbols-outlined',
    description: 'Licence Management',
    roles: routeRoleConfig.license
  },
  {
    link: LipoRouteEnum.PRODUCT,
    name: 'products',
    icon: 'apps',
    fontSet: 'material-symbols-outlined',
    description: 'Product Management',
    roles: routeRoleConfig.product
  }
];
