import {CustomerDetailModel} from "../../../customer/components/models/customer-detail.model";
import {Address} from "../../../shared/models/lipo-address.model";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";

export class PartnerDetailModel implements LipoModelInterface {
  constructor(
    public id: number | null = null,
    public name: string = '',
    public mail: string = '',
    public phone: string = '',
    public addresses: Address[] = [],
    public customers: CustomerDetailModel[] = [],
    public logoUrl: string | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}
