import {Component} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'du-lipo-overview-empty',
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslateModule
  ],
  templateUrl: './lipo-overview-empty.component.html',
  styleUrl: './lipo-overview-empty.component.scss'
})
export class LipoOverviewEmptyComponent {

}
