import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[TokenFormatInput]',
  standalone: true

})
export class TokenFormatInputDirective {

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      return;
    }

    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputElement = this.el.nativeElement;
    inputElement.value = inputElement.value.replace(/\D/g, '');
  }
}
