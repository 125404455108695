import {Component, Input} from '@angular/core';
import {LipoFormModel} from "../../models/lipo-form.model";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'du-lipo-readonly',
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule
  ],
  templateUrl: './lipo-readonly.component.html',
  styleUrl: './lipo-readonly.component.scss'
})
export class LipoReadonlyComponent {
  @Input() formModel?: LipoFormModel;
}
