import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {NgClass, NgOptimizedImage} from "@angular/common";
import {LipoRouteEnum} from "../shared/enums/lipo-route.enum";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {KeycloakService} from "keycloak-angular";
import {MatListItem, MatNavList} from "@angular/material/list";
import {navigationConfig} from "../shared/navigationConfig";
import {RealmService} from "../keycloak/services/realm.service";

@Component({
  selector: 'du-sidenav',
  standalone: true,
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    NgOptimizedImage,
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    TranslateModule,
    RouterLinkActive,
    MatListItem,
    MatNavList,
    NgClass
  ],
})
export class SidenavComponent implements OnInit {
  @Input() opened!: boolean;
  @Output() openedChange = new EventEmitter();

  readonly languages = [
    {value: 'en', label: 'English'},
    {value: 'de', label: 'Deutsch'},
  ];

  currentLanguage: any = {};

  private readonly lSLanguageProperty: string;

  constructor(
    protected readonly _keycloak: KeycloakService,
    protected _translate: TranslateService,
    private _router: Router,
    private realmService: RealmService,
  ) {
    this.lSLanguageProperty = 'language';
  }

  public async ngOnInit() {
    let currentLanguage = this.manageLocalStorageLanguage();
    this.setDropDownInitialLanguage(currentLanguage);
  }

  async logout() {
    await this.realmService.clearRealmAndLogout()
  }


  manageLocalStorageLanguage(): string {
    let localStorageLang = localStorage.getItem(this.lSLanguageProperty);

    if (localStorageLang === null) {
      localStorageLang = this.getBrowserLanguage();
      localStorage.setItem(this.lSLanguageProperty, localStorageLang)
    }

    this._translate.use(localStorageLang);

    return this._translate.currentLang;
  }

  getBrowserLanguage() {
    let userLang = navigator.language.split('-')[0];
    if (this.languages.map(lang => lang.value).includes(userLang)) {
      return userLang;
    }
    return this._translate.getDefaultLang();
  }

  setNewLanguage(language: string) {
    this._translate.use(language);
    localStorage.setItem(this.lSLanguageProperty, language);
    this.setDropDownInitialLanguage(language);
  }

  setDropDownInitialLanguage(language: string) {
    this.currentLanguage = this.languages.find(lang => lang.value === language);
  }

  async navigateHome() {
    await this._router.navigate([LipoRouteEnum.HOME])
  }

  isUserInRole(onlyRolesAllowed: string[]): boolean {
    if (onlyRolesAllowed.length === 0) {
      return true
    }
    return onlyRolesAllowed.some(it => this._keycloak.isUserInRole(it))
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
  protected readonly navigation = navigationConfig;
}
