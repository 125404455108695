import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PartnerServiceModel} from "./models/partner-service.model";
import {Observable} from "rxjs";
import {IPartnerDataInterface} from "./partner-data.interface";
import {ConfigurationService} from "../../shared/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class PartnerDataService implements IPartnerDataInterface {

  private readonly endpoint: string;
  private readonly api: string;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {
    this.endpoint = this.config.configuration.api.endpoints.partner;
    this.api = this.config.configuration.api.backendUrl;
  }

  createPartner(partner: PartnerServiceModel): Observable<PartnerServiceModel> {
    return this.http.post<PartnerServiceModel>(`${this.api}/${this.endpoint}`, partner);
  }

  deletePartner(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.api}/${this.endpoint}/${id}`);
  }

  getPartner(id: number): Observable<PartnerServiceModel> {
    return this.http.get<PartnerServiceModel>(`${this.api}/${this.endpoint}/${id}`);
  }

  getPartners(): Observable<PartnerServiceModel[]> {
    return this.http.get<PartnerServiceModel[]>(`${this.api}/${this.endpoint}`);
  }

  updatePartner(partner: PartnerServiceModel): Observable<PartnerServiceModel> {
    return this.http.put<PartnerServiceModel>(`${this.api}/${this.endpoint}/${partner.id}`, partner);
  }
}
