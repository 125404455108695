import {TenantUserMappingDetailModel} from "../components/models/tenant-user-mapping-detail.model";
import {TenantUserMappingServiceModel} from "../services/models/tenant-user-mapping-service.model";
import {TenantAppSettingsServiceMapper} from "./tenant-app-settings-service.mapper";
import {UserServiceMapper} from "../../user/mappers/user-service.mapper";

export class TenantUserMappingServiceMapper {
  public static toTenantUserMappingDetailModel(tenantUserMappingServiceModel: TenantUserMappingServiceModel): TenantUserMappingDetailModel {
    return new TenantUserMappingDetailModel(
      tenantUserMappingServiceModel.sapUserName,
      tenantUserMappingServiceModel.sapUserCode,
      UserServiceMapper.toUserDetailModel(tenantUserMappingServiceModel.user!!),
      tenantUserMappingServiceModel.user!!.firstName ?? '',
      tenantUserMappingServiceModel.user!!.lastName ?? '',
      tenantUserMappingServiceModel.user!!.email,
      tenantUserMappingServiceModel.user!!.id,
      tenantUserMappingServiceModel.tenantId,
      tenantUserMappingServiceModel.appSettings?.map(TenantAppSettingsServiceMapper.toTenantAppSettingsDetailModel) ?? null,
      tenantUserMappingServiceModel.id,
    )
  }
}
