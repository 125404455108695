import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {Observable} from "rxjs";
import {AppLicenseViewServiceModel, LicenseAddServiceModel} from "./models/app-license-view-service.model";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  private readonly appLicenseEndpoint: string;
  private readonly systemEndpoint: string;
  private readonly tenantEndpoint: string;
  private readonly api: string;

  constructor(
    private _http: HttpClient,
    _config: ConfigurationService
  ) {
    const api = _config.configuration.api;

    this.appLicenseEndpoint = api.endpoints.appLicense;
    this.systemEndpoint = api.endpoints.system;
    this.tenantEndpoint = api.endpoints.tenant;
    this.api = api.backendUrl;
  }

  getAppLicenses(): Observable<AppLicenseViewServiceModel[]> {
      return this._http.get<AppLicenseViewServiceModel[]>(`${this.api}/${this.appLicenseEndpoint}`);
  }

  addAppLicenseToTenant(systemId: number, tenantId: number, createServiceModel: LicenseAddServiceModel): Observable<any> {
    return this._http.put<LicenseAddServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.appLicenseEndpoint}`, createServiceModel);
  }
}
