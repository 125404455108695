import {Injectable} from '@angular/core';
import {ConfigurationService} from "../../shared/services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {TenantTokenServiceModel, TenantTokenServiceSaveModel} from "./models/tenant-token-service.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TenantTokenDataService {
  private readonly tenantEndpoint: string;
  private readonly systemEndpoint: string;
  private readonly api: string;
  private readonly tokenSuffix: string = "token";


  constructor(
    private _http: HttpClient,
    private _config: ConfigurationService
  ) {
    const config = _config.configuration;

    this.tenantEndpoint = config.api.endpoints.tenant;
    this.systemEndpoint = config.api.endpoints.system;
    this.api = config.api.backendUrl;
  }

  createTenantToken(systemId: number, tenantId: number, tenantToken: TenantTokenServiceSaveModel): Observable<TenantTokenServiceModel> {
    return this._http.post<TenantTokenServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.tokenSuffix}`, tenantToken);
  }
}
