import {ProductServiceModel} from "../services/models/product-service.model";
import {ProductDetailModel} from "../components/models/product-detail.model";
import {ProductOverviewModel} from "../components/models/product-overview.model";
import {ProductPriceServiceMapper} from "../../productPrice/mappers/product-price-service.mapper";

export class ProductServiceMapper{
  public static toProductDetailModel(productServiceModel: ProductServiceModel): ProductDetailModel {
    return new ProductDetailModel(
      productServiceModel.name,
      productServiceModel.description,
      productServiceModel.partnerId ?? undefined,
      productServiceModel.productPrice.map(price => ProductPriceServiceMapper.toProductPriceDetailModel(price)),
      productServiceModel.image,
      productServiceModel.url,
      productServiceModel.id,
    )
  }
  public static toProductOverviewMapper(productServiceModel: ProductServiceModel): ProductOverviewModel {
    return new ProductOverviewModel(
      productServiceModel.id,
      productServiceModel.name,
      productServiceModel.description,
      productServiceModel.image
    )
  }
}
