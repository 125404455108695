<mat-nav-list class="list-horizontal">
  @for (breadcrumbItem of breadcrumbs; track $index) {
    <mat-list-item [routerLink]="breadcrumbItem.url">
      <div class="breadcrumb" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:true}" >
        <span class="mat-label-large"> {{ breadcrumbItem.label | translate }}</span>
      </div>
    </mat-list-item>
    @if($index < breadcrumbs.length - 1) {
      <mat-icon fontIcon="chevron_right" class="breadcrumb-separator"></mat-icon>
    }
  }
</mat-nav-list>
