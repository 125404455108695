<du-lipo-detail [detailModel]="detailModel" (onDeleteClicked)="onDeleteClicked(productId)"
                [hideDeleteButton]="!isUserAllowedToEdit"
                [hideSaveButton]="!isUserAllowedToEdit"
                [isReadOnly]="!isUserAllowedToEdit"
                [isSaving]="isSaving"
                (onSaveClicked)="onSaveClick(detailModel!, productId)"
                [showLicenseWizardButton]="productId !== null && isUserAllowedToEdit"
                (onLicenseWizardClicked)="onLicenseWizardClicked()"
                [headerButtons]="getExtraHeaderButton()"
                [navigateBack]="{commands: [LipoRouteEnum.PRODUCT]}"></du-lipo-detail>
