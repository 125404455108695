import {ProductServiceModel} from "../services/models/product-service.model";
import {ProductDetailModel} from "../components/models/product-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {ProductPriceDetailMapper} from "../../productPrice/mappers/product-price-detail.mapper";

export class ProductDetailMapper{
  public static toLipoDetailModel(product: ProductDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      product.name.length > 0 ? product.name : 'product',
      '',
      product.image,
      formModel,
      tabs,
      product.id
    )
  }
  public static toProductServiceModel(productDetailModel: ProductDetailModel): ProductServiceModel {
    return {
      name: productDetailModel.name,
      description: productDetailModel.description,
      image: productDetailModel.image || '',
      id: productDetailModel.id || 0,
      partnerId: productDetailModel.partnerId ?? null,
      url: productDetailModel.url,
      productPrice: productDetailModel.productPrice.map(price => ProductPriceDetailMapper.toProductPriceServiceModel(price))
    }
  }
}
