import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private _snackbar: MatSnackBar,
    private _translate: TranslateService,) {
  }

  public Error(message: string): void {
    this._snackbar.open(message, undefined, {
      duration: 4000,
      panelClass: ['snackbar-error']
    });
  }

  public Warning(message: string): void {
    this._snackbar.open(message, undefined, {
      duration: 4000,
      panelClass: ['snackbar-warning']
    });
  }

  public Success(message: string): void {
    this._snackbar.open(message, undefined, {
      duration: 4000,
      panelClass: ['snackbar-success']
    });
  }

  public Saved(): void {
    this._snackbar.open(this._translate.instant('snackbar.saved'), undefined, {
      duration: 2000,
      panelClass: ['snackbar-success']
    });
  }

  public Deleted(): void {
    this._snackbar.open(this._translate.instant('snackbar.deleted'), undefined, {
      duration: 2000,
      panelClass: ['snackbar-success']
    });
  }
}
