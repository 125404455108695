<div class="sidenav-container">
  <div class="sidenav-header">
    @if (opened) {
      <div class="sidenav-toggle-opened">
        <div class="logo-container" [style.--picture-size]="96">
          <img ngSrc="assets/images/logo.png" alt="logo" (click)="navigateHome()" fill priority/>
        </div>
        <button mat-icon-button (click)="openedChange.emit(false)">
          <mat-icon fontSet="material-symbols-outlined" class="navigation-icon icon-color">
            {{ "dehaze" }}</mat-icon>
        </button>
      </div>

    } @else {
      <div class="sidenav-toggle-closed">
        <button mat-icon-button (click)="openedChange.emit(true)">
          <mat-icon class="navigation-icon icon-color">{{ "dehaze" }}</mat-icon>
        </button>
      </div>
    }
  </div>
  <div class="sidenav-content">
    <mat-nav-list>
      @for (item of navigation; track item) {
        @if(isUserInRole(item.roles)) {
          <mat-list-item [routerLink]="item.link">
            @if (opened) {
              <div class="navigation-item-opened" [routerLinkActive]="['active-link']">
                <mat-icon class="navigation-icon icon-color" [fontSet]="item.fontSet">{{ item.icon }}</mat-icon>
                <span> {{ item.name | translate }}</span>
              </div>
            } @else {
              <div class="navigation-item-closed" [routerLinkActive]="['active-link']">
                <mat-icon class="navigation-icon icon-color"
                          [fontSet]="item.fontSet">{{ item.icon }}
                </mat-icon>
              </div>
            }
          </mat-list-item>
        }
      }
    </mat-nav-list>
  </div>
  <div class="sidenav-footer">
    <mat-nav-list>
      @if (opened) {
        <mat-list-item (click)="logout()">
          <div class="navigation-item-opened">
            <mat-icon class="navigation-icon icon-color" [fontSet]="'material-symbols-outlined'">{{ 'logout' }}
            </mat-icon>
            <span> {{ 'logout' | translate }}</span>
          </div>
        </mat-list-item>
        <div class="language-selector-container">
          <div class="language-selector">
            <span
              class="mat-label-medium language-item"
              [ngClass]="{'selected': currentLanguage.value === 'de'}"
              (click)="setNewLanguage('de')">DE</span>
            <span class="mat-label-medium">/</span>
            <span
              class="mat-label-medium language-item"
              [ngClass]="{'selected': currentLanguage.value === 'en'}"
              (click)="setNewLanguage('en')">EN</span>
          </div>
        </div>
      } @else {
        <mat-list-item (click)="logout()">
          <div class="navigation-item-closed">
            <mat-icon class="navigation-icon icon-color"
                      [fontSet]="'material-symbols-outlined'">
              {{ 'logout' }}
            </mat-icon>
          </div>
        </mat-list-item>
      }
    </mat-nav-list>
  </div>
</div>
