import {Injectable} from '@angular/core';
import {ConfigurationService} from '../../shared/services/configuration.service';
import {TenantITInstructionsModel} from './models/tenant-it-instructions.model';

@Injectable({
  providedIn: 'root',
})
export class TenantItInstructionsService {
  constructor(private configService: ConfigurationService) {}

  getTenantITInstructions(): TenantITInstructionsModel {
    return this.configService.configuration.tenantITInstructions || { ipWhitelist: [] };
  }

  getTenantITInstructionsIpWhitelist(): string[] {
    return this.getTenantITInstructions().ipWhitelist;
  }
}
