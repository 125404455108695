import {TenantTokenDetailModel, TenantTokenDetailSaveModel} from "../components/models/tenant-token-detail.model";
import {TenantTokenServiceModel, TenantTokenServiceSaveModel} from "../services/models/tenant-token-service.model";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";

export class TenantTokenDetailMapper {
  public static toTenantTokenServiceModel(tenantTokenDetailModel: TenantTokenDetailModel): TenantTokenServiceModel {
    return {
      name: tenantTokenDetailModel.name,
      token: tenantTokenDetailModel.token,
      validUntil: tenantTokenDetailModel.validUntil?.format('YYYY-MM-DD') ?? null,
      accessRights: tenantTokenDetailModel.accessRights,
      id: tenantTokenDetailModel.id,
    }
  }
}

export class TenantTokenSaveDetailMapper {
  public static toTenantTokenServiceModel(tenantTokenDetailModel: TenantTokenDetailSaveModel): TenantTokenServiceSaveModel {
    return {
      name: tenantTokenDetailModel.name,
      validUntil: tenantTokenDetailModel.validUntil?.format('YYYY-MM-DD') ?? null,
      accessRights: tenantTokenDetailModel.accessRights,
      tokenLength: tenantTokenDetailModel.tokenLength,
    }
  }

  public static toLipoDetailModel(tenant: TenantTokenDetailSaveModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      'api_token',
      null,
      null,
      formModel,
      tabs,
      null
    )
  }
}
