import {LipoDynamicComponentModel} from "./lipo-dynamic-component.model";
import {Type} from "@angular/core";
import {LipoTableModel} from "./lipo-table.model";

export class LipoTab {
  public title!: string;
  public components?: LipoDynamicComponentModel[] | null;

  private static buildModel(options: {
    title: string;
    components?: {uuid?: string, component?: Type<any> | LipoTableModel<any>, inputs?: Record<string, unknown>}[] | null;
  } = {title: ''}) {
    options.components = options.components?.map(LipoDynamicComponentModel.build);
    const instance = new LipoTab();
    instance.title = options.title;
    instance.components = options.components;
    return instance;
  }

  static build(...optionsArray: {
    title: string;
    components?: {uuid?: string, component?: Type<any> | LipoTableModel<any>, inputs?: Record<string, unknown>}[] | null;
  }[]): LipoTab[] {
    return optionsArray.map(options => LipoTab.buildModel(options));
  }
}
