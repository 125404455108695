import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, Provider} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ConfigurationService} from "./shared/services/configuration.service";
import {errorResponseInterceptor} from "./shared/interceptors/error-response.interceptor";
import {DatePipe} from "@angular/common";
import {MatDateFormats} from "@angular/material/core";
import {provideMomentDateAdapter} from "@angular/material-moment-adapter";
import {KeycloakBearerInterceptor, KeycloakService} from "keycloak-angular";
import {keycloakSetup} from "./keycloak/keycloakSetup";

const initializeConfiguration = (configService: ConfigurationService) => {
  return () => configService.loadConfig();
};

const initializeKeycloak = (keycloak: KeycloakService, config: ConfigurationService) => {
  let keycloakConfig = config.configuration.keycloak;
  return async () => {
    await keycloak.init(
      keycloakSetup(
        keycloakConfig.issuer,
        keycloakConfig.realm,
        keycloakConfig.clientId,
      )
    );
  };
};

const initializeApp = (configService: ConfigurationService, keycloak: KeycloakService) => {
  return async () => {
    await initializeConfiguration(configService)();
    await initializeKeycloak(keycloak, configService)();
  };
};

const ConfigurationAppStarter: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  deps: [ConfigurationService, KeycloakService],
  multi: true,
};

const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

export const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([errorResponseInterceptor])),
    provideRouter(routes),
    provideAnimations(),
    provideAnimationsAsync(),
    DatePipe,
    provideMomentDateAdapter(DATE_FORMATS),
    ConfigurationService,
    KeycloakService,
    ConfigurationAppStarter,
    KeycloakBearerInterceptorProvider,
    importProvidersFrom(TranslateModule.forRoot(provideTranslation())),
  ]
};
