import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {LipoConfirmationDialogModel} from "../models/lipo-confirmation-dialog.model";
import {TranslateModule} from "@ngx-translate/core";
import {UpperCasePipe} from "@angular/common";

@Component({
  selector: 'du-lipo-confirmation-dialog',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslateModule, UpperCasePipe],
  templateUrl: './lipo-confirmation-dialog.component.html',
  styleUrl: './lipo-confirmation-dialog.component.scss'
})
export class LipoConfirmationDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LipoConfirmationDialogModel) {
  }
}
